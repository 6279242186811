import { format } from "date-fns";

const formatDateTime = (dateTime: Date) => {
  //We need to send this in UTC
  const utcDatTime = new Date(
    dateTime.valueOf() + dateTime.getTimezoneOffset() * 60 * 1000
  );
  return format(utcDatTime, "y-M-d H:m:s");
};

export default formatDateTime;
