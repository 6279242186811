import React, { useState } from "react";
import { makeStyles, createStyles, withStyles, Theme, WithStyles, ThemeProvider, } from "@material-ui/core/styles";
import { createMuiTheme, Typography, TableCell, IconButton, Menu, Divider, Grid, TextField, InputLabel, Button, Stepper, MenuItem } from "@material-ui/core"
import {
  Step,
  StepLabel,
  Dialog,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { API } from "../../api/property";
import { SnackbarProvider, useSnackbar } from "notistack";
import ErrorDialog from "../../utils/ErrorDialog";
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import { DEFAULT_API_ERROR_MSG, INVALID_LEVEL_NAME_ERROR_MSG } from "../../utils/constant";


const blueColor = "#0089FF";
const borderVariable = "1px solid #464659";
const marginVariable = "0px !important";
const colorVar = "red !important";
const listColorVar = "#0089ff !important";
const listMarginVar = "1rem !important";
const listBorderVar = "0 !important";
const listBoxShadowVar = "0 0 black !important";

const theme1 = createMuiTheme({
  palette: {
    primary: {
      main: `${blueColor}`,
    },
    secondary: {
      main: `#FFFFFF`,
    },
  },
});

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${borderVariable}`,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    hidden: {
      display: "none",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    spaceBottom: {
      marginBottom: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    formTitle: {
      fontWeight: "bold",
      color: "#FFFFFF",
      fontSize: "21px",
      lineHeight: "25px",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "19px",
      fontSize: "16px",
      transform: "scale(1)",
    },
    accordionRoot: {
      color: "#FFFFFF",
      margin: "0px",
      padding: "0px",
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      minHeight: "auto !important",
    },
    noSpaceNoMin: {
      margin: `${marginVariable}`,
      padding: `${marginVariable}`,
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${borderVariable}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
      /* marginBottom: '5px'   */
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    datePickerRoot: {
      color: "#FFFFFF",
      border: `${borderVariable}`,
      borderRadius: "2px",
      "& .MuiIconButton-root, .MuiPickersDay-day, .MuiPickersCalendarHeader-dayLabel":
      {
        color: "#FFFFFF",
      },
      "& .MuiPickersDay-daySelected": {
        border: `${borderVariable}`,
      },
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: "1px solid rgba(235,235,245,0.35)",
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNUser: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "&:hover": {
        color: "#0089FF !important",
      },
      "& svg": {
        marginRight: "8px",
      },
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },

    button: {
      marginRight: theme.spacing(1),
    },
    stepperRoot: {
      width: "50%",
      margin: "0 auto",
    },
    stepname: {
      color: "#FFF",
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },
    stepactive: {
      color: "#FFF",
    },
    steplabel: {
      color: "#FFF",
      "& span": {
        color: "darkgray !important",
      },
      "& .active": {
        color: "green !important",
      },
    },
    step: {
      //color: '#ddd !important',
      "&$completed": {
        color: "#0089ff",
      },
      "&$active": {
        color: "cornflowerblue",
      },
      "&$disabled": {
        color: "red",
      },
    },
    alternativeLabel: {
      color: "yellow !important",
      "&$active": {
        color: `${colorVar}`,
      },
    },
    active: {
      color: "cornflowerblue !important",
    },
    completed: {
      color: `${listColorVar}`,
    },
    disabled: {
      color: "red",
    },
    labelContainer: {
      color: `${colorVar}`,
      "&$alternativeLabel": {
        marginTop: 0,
        color: "orange !important",
      },
      "&$active": {
        color: `${colorVar}`,
      },
      "&$completed": {
        color: "green !important",
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: `${borderVariable}`,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    menuButtonRoot: {
      padding: "0px",
      float: "right",
    },
    dividerOr: {
      color: "grey",
      margin: "1.5rem 1rem 0 3.5rem",
    },
    listButton: {
      color: `${listColorVar}`,
      background: "transparent !important",
      marginTop: `${listMarginVar}`,
      borderRadius: `${listBorderVar}`,
      boxShadow: `${listBoxShadowVar}`,
      padding: `${marginVariable}`,
      textTransform: "none",
      whiteSpace: "nowrap",
    },
    listButtonNew: {
      color: "#fff !important",
      background: `${listColorVar}`,
      marginTop: `${listMarginVar}`,
      borderRadius: `${listBorderVar}`,
      boxShadow: `${listBoxShadowVar}`,
      textTransform: "none",
      whiteSpace: "nowrap",
    },
    listButtonBrowse: {
      color: `${listColorVar}`,
      background: "transparent !important",
      marginTop: `${listMarginVar}`,
      borderRadius: `${listBorderVar}`,
      boxShadow: `${listBoxShadowVar}`,
      textTransform: "none",
      whiteSpace: "nowrap",
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
    },
    appTitle: {
      width: "100%",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
      overflow: "hidden",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    appContainer: {
      padding: "1rem",
      background: "#2b2b36",
      marginTop: "1rem",
    },
    buttonText: {
      color: "#0089FF",
      margin: "1em",
      textTransform: "none",
      fontSize: "14px",
    },
    dividerColor: {
      background: "#464659",
    },
    formContent: {
      marginBottom: "1rem",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: "16ch",
      color: "#fff",
      padding: "3px 10px",
      border: "1px solid gray",
    },
    activelevel: {
      color: "rgb(251, 206, 7)",
    },
    levelBlock: {
      display: "flex",
      flexDirection: "column",
      padding: "0px 20px",
      overflow: "hidden",
      boxShadow: "none",
      margin: "10px",
      backgroundColor: "rgb(29, 29, 37)",
      color: "white",
    },
    levelSearch: {
      height: "40px",
      backgroundColor: "#2B2B36",
      border: "transparent",
      color: "white",
      paddingLeft: "23px",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
      paddingLeft: "0px",
      paddingRight: "0px",
      cursor: "pointer",
      "& span": {
        paddingLeft: "5px",
      },
    },
    head: {
      backgroundColor: "#1D1D25",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "12px",
      background: "#1D1D25",
    },
  })
)(TableCell);

function getSteps() {
  return ["Application Details", "Application Levels"];
}

interface ParentComponentProps {
  clickCancel: any;
}

const AddNewApplicationWrapper: React.FC<ParentComponentProps> = (props) => {

  const { enqueueSnackbar } = useSnackbar();
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const { user } = useStore();

  const classes = useStyles();
  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const tokenKey = "sso_token";
  const [backDrop, setbackDrop] = React.useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };  
  /* stepper */
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  //---------------------------------------------//
  const [addFieldInput, setAddFieldInput] = useState([{ name: "" }]);
  const [addLevel, setAddLevel] = useState([
    { level_id: -1, level_name: "", operation: "add" },
  ]);
  const [open, setOpen] = React.useState(false);
  const [tags, setTags] = React.useState([] as any);
  const [levels, setLevels] = React.useState([] as any);
  const [appLevel, setOpenAppLevel] = React.useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const [menu, setMenu] = React.useState([] as any);
  const [subLevel, setSubLevel] = React.useState(-1);
  const [currState, setCurrentState] = React.useState(null as any);
  const [editLevelName, setEditLevelName] = React.useState([{ index_id: '', level_id: -1, level_name: "", operation: "add" }]);
  const [appName, setAppName] = React.useState("");
  const [serReq, setServiceReq] = React.useState("");
  const [apexId, setApedId] = React.useState("");
  const [lineOf, setLineOfBuss] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const [openedit, setOpenEdit] = React.useState(false);
  const [editfield, setEditFieldName] = React.useState("");
  const [editindex, setEditFieldIndex] = React.useState(0);
  const [isLevel, setIsLevel] = React.useState(false);
  const [appEditLevel, setAppEditLevel] = React.useState(false);
  const regexCheck = /^[0-9a-zA-Z]+$/;

  const [search, setSearch] = React.useState("");
  const handleChangeSearch = (event: any) => {
    const target = event.target.value;
    setSearch(target);
  };

  const onSubmitLevel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const regexCheck = /^[0-9a-zA-Z|_]+$/;
    const newLevels = [...levels];
    const menuClose = menu.map(() => false);
    if (isLevel) {
      addLevel.forEach((item) => {
        if (item.level_name.length === 0) {
          enqueueSnackbar("Please enter correct levelname.", {
            variant: "error",
          });
        } else if (!regexCheck.test(item.level_name)) {
          enqueueSnackbar(INVALID_LEVEL_NAME_ERROR_MSG, { variant: "error" });
        } else if (newLevels.some(({level_name})=>level_name === item.level_name)) {
          enqueueSnackbar("can not add duplicate application level name",{ variant: "error" });
          return;
        } else {
          newLevels.push(item);
          menuClose.push(false);
          setOpenAppLevel(false);
        }
      });
    }
    setLevels(newLevels);
    setMenu(menuClose);
    setAnchorEl1(null);
    setAddLevel([{ level_id: -1, level_name: "", operation: "add" }]);
  };

  const onEditLevel = (index: any, level: string) => {
    const newLevels = [...levels];
    const values = [] as any;
    const temp = newLevels[index];
    values.push({ index_id: index, level_id: -1, level_name: temp.level_name, operation: "add" });
    setEditLevelName(values);
    setAppEditLevel(true);
  };
  const onAddNewFields = () => {
    setAddFieldInput([...addFieldInput, { name: "" }]);
  };

  const onAddLevelField = () => {
    setAddLevel([...addLevel, { level_id: -1, level_name: "", operation: "add" }]);
  };

  const onChangeAddNewFields = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values = [...addFieldInput];
    values[index][event.target.name] = event.target.value;
    setAddFieldInput(values);
  };
  const handleDeleteNewFields = (index: number) => {
    const values = [...addFieldInput];
    values.splice(index, 1);
    setAddFieldInput(values);
  };

  const onChangeEditLevel = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values = [...editLevelName];
    values[index][event.target.name] = event.target.value;
    setAddLevel(values);
  };

  const handleSubmitNewFields = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const temp = [...tags, ...addFieldInput];
    let flag = false;
    const valueArr = temp.map((item: any) => {
      return item.name;
    });
    const isDuplicate = valueArr.some((item, idx) => {
      return valueArr.indexOf(item) !== idx;
    });
    temp.forEach((e: any) => {
      if (e.name === "" && e.name.length === 0) {
        enqueueSnackbar("Field name can't be empty.", { variant: "error" });
        flag = false;
      } else if (e.name.length > 50) {
        enqueueSnackbar("Field name can only accept upto 50 characters.", {
          variant: "error",
        });
        flag = false;
      } else if (!regexCheck.test(e.name)) {
        enqueueSnackbar("Field name can accept only character and digits.", {
          variant: "error",
        });
        flag = false;
      } else if (isDuplicate) {
        enqueueSnackbar("Field name can't be a duplicate.", { variant: "error" });
        flag = false;
      } else {
        flag = true;
      }
    });
    if (flag) {
      setTags(temp);
      setOpen(false);
    }
    setAddFieldInput([{ name: "" }]);
  };
  const onCancelAddNewField = (e: any) => {
    e.preventDefault();
    setOpen(false);
    setAddFieldInput([{ name: "" }]);
  };

  const updateError = () => {
    setError(false);
    setErrMsg("");
  };

  const editFieldAction = (index: number) => {
    const temp = tags.filter((tag: any, ind: number) => ind === index);
    setEditFieldName(temp[0].name);
    setEditFieldIndex(index);
    setOpenEdit(true);
  };
  const handleChange = (index: number, event: any) => {
    const temp = [...tags];
    temp[index].value = event.target.value;
    setTags(temp);
  };
  const saveEditField = () => {
    tags[editindex].name = editfield;
    tags.forEach((e: any) => {
      if (e.name === "") {
        enqueueSnackbar("Field name can't be empty.", { variant: "error" });
        setOpenEdit(true);
      } else if (!regexCheck.test(e.name)) {
        enqueueSnackbar("Field name can accept only characters and digits.", {
          variant: "error",
        });
        setOpenEdit(true);
      } else {
        setTags(tags);
        setOpenEdit(false);
      }
    });
  };
  const deleteProperty = (index: number) => {
    const temp = tags.filter((tag: any, ind: number) => ind !== index);
    setTags(temp);
  };

  const deleteSubLevel = (index: any, level: string) => {
    const menuClose = menu.map(() => false);
    const newLevels = [...levels];
    if (level === "level") {
      newLevels.splice(index, 1);
    } else {
      newLevels.splice(subLevel, 1);
    }
    menuClose.splice(index,1)
    setMenu(menuClose);
    setLevels(newLevels);
  };
  const addLevels = () => {
    setOpenAppLevel(true);
    setIsLevel(true);
  };

  const onSubmitEditLevel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const regexCheck = /^[0-9a-zA-Z|]+$/;
    const newLevels = [...levels];
    const applicationLevel: string = editLevelName[0].level_name;
    const index: any = editLevelName[0].index_id;

    if (applicationLevel.length === 0) {
      enqueueSnackbar("Please enter correct levelname.", { variant: "error" });
    } else if (!regexCheck.test(applicationLevel)) {
      enqueueSnackbar(INVALID_LEVEL_NAME_ERROR_MSG, { variant: "error" });
    } else if (newLevels.some(({level_name}:any)=>level_name === applicationLevel)) {
      enqueueSnackbar("Application level already exists",{ variant: "error" });
      return;
    } else {
      newLevels[index].level_name = applicationLevel;
      setLevels(newLevels);
      const menuClose = menu.map(() => false);
      setMenu(menuClose);
      setAnchorEl1(null);
      setEditLevelName([{ index_id: "", level_id: -1, level_name: "", operation: "add" }]);
      setAppEditLevel(false);
    }
  };
  const onSelectMenuItem =
    (item: any) => async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setSubLevel(item);
      setCurrentState(item);
      const newMenu = [...menu];
      newMenu[item] = true;
      setMenu(newMenu);
      setAnchorEl1(event.currentTarget);
    };
  const onSelectColorChange =
    (item: any) => async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setCurrentState(item);
    };
  const handleClose = () => {
    const menuClose = menu.map((m: any) => false);
    setMenu(menuClose);
    setAnchorEl1(null);
  };
  const onDeleteLevel = (index: number) => {
    const values = [...addLevel];
    values.splice(index, 1);
    setAddLevel(values);
  };
  const saveApplication = () => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const APPS_API = API["GETALLAPPLS"];
    if (levels.length === 0) {
      enqueueSnackbar("Please create levels.", { variant: "error" });
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken.access_token}`,
          "Ocp-Apim-Subscription-Key": `${API_KEY}`,
          "Ocp-Apim-Trace": `true`,
        },
        body: JSON.stringify({
          pm_s_id: pmSId,
          pm_s_token: pmST,
          application: {
            application_name: appName,
            snow_req_id: serReq,
            apex_id: apexId,
            metadata: {
              lob: lineOf,
            },
            fields: tags,
            levels: levels,
          },
        }),
      };
      setbackDrop(true);
      fetch(APPS_API, requestOptions)
        .then(function (response: any) {
          response.json().then((response: any) => {
          if (response.status === 200 || response.status === 201) {
            enqueueSnackbar("Application Created", { variant: "success" });
            setTimeout(function () {
              setAppName("");
              setServiceReq("");
              setApedId("");
              setLineOfBuss("");
              setActiveStep(0);
              setLevels([]);
              setMenu([]);
              props.clickCancel();
            }, 10);
          } 
          else if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
            try {
              const tokenKey = "sso_token";
              callXhrRequest().then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                saveApplication();

              }).catch(function (error) {
                console.log('error', error)
                user.triggerLogout();
              })
            } catch (error) {
              console.log(error);
            }
            return;
          }
          else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
            try {
              user.triggerLogout();
            } catch (error) {
              console.log(error);
            }
            return;
          }
          else {
            const errorMessage = response?.message || DEFAULT_API_ERROR_MSG;
            setError(true);
            setErrMsg(errorMessage);
            setbackDrop(false);
          }
        })
      })
        .catch(function (error) {
          console.log(error)
        });
      
    }
  };

  const onAppChange = (event: any) => {
    event.preventDefault();
    const appData = event.target.value;
    const regex = appData.trim();
    setAppName(regex);
  };
  const onCancel = () => {
    setTimeout(function () {
      setAppName("");
      setServiceReq("");
      setApedId("");
      setLineOfBuss("");
      setActiveStep(0);
      setLevels([]);
      setMenu([]);
      props.clickCancel();
    }, 5);
  };

  const handleNext = (event: any) => {
    event.preventDefault();
    const regexCheck = /^[0-9a-zA-Z_-]+$/;
    if (!appName) {
      enqueueSnackbar("Please enter application name.", { variant: "error" });
    } else if (!(regexCheck.test(appName) && appName.length < 50)) {
      enqueueSnackbar(
        "Invalid application name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
    } else if (!serReq) {
      enqueueSnackbar("Please enter service now id.", { variant: "error" });
    } else if (!(regexCheck.test(serReq) && serReq.length < 50)) {
      enqueueSnackbar(
        "Invalid  service now id (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
    } else if (!apexId) {
      enqueueSnackbar("Please enter apex id.", { variant: "error" });
    } else if (!(regexCheck.test(apexId) && apexId.length < 50)) {
      enqueueSnackbar(
        "Invalid apex id (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
    } else if (!lineOf) {
      enqueueSnackbar("Please enter line of business.", { variant: "error" });
    } else if (!(regexCheck.test(lineOf) && lineOf.length < 50)) {
      enqueueSnackbar(
        "Invalid line of business. (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)",
        { variant: "error" }
      );
    } else {
      const newSkipped = skipped;
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const onChangeLevel = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values = [...addLevel];
    values[index].level_name = event.target.value;
    setAddLevel(values);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <div className={classes.stepperRoot}>
        <Stepper activeStep={activeStep} className={classes.stepactive}>
          {steps.map((label, index) => {
            return (
              <Step key={label} className={classes.stepname}>
                <StepLabel
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    labelContainer: classes.labelContainer,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.step,
                      completed: classes.completed,
                      active: classes.active,
                    },
                  }}
                  className={classes.steplabel}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <p style={{ color: "#FFFFFF", fontSize: "12px" }}>
        All fields marked * are mandatory.
      </p>

      <form className={classes.form} noValidate method="post">
        <Grid container className={activeStep !== 0 ? classes.hidden : ""}>
          <Grid item xs={6} className={classes.spaceRight}>
            <CssTextField
              required
              fullWidth
              name="appname"
              label="Application Name"
              type="text"
              id="appname"
              value={appName}
              className={classes.fieldWrapper}
              onChange={onAppChange}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceLeft}>
            <CssTextField
              fullWidth
              name="serviceid"
              label="Service Now ID *"
              type="text"
              id="serviceid"
              value={serReq}
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setServiceReq(e.target.value),
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="apexid"
              label="Apex ID *"
              type="text"
              id="apexid"
              value={apexId}
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setApedId(e.target.value),
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceLeft}>
            <CssTextField
              fullWidth
              name="lineofbusiness"
              label="Line of Business *"
              type="text"
              id="lineofbusiness"
              value={lineOf}
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setLineOfBuss(e.target.value),
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
            />
          </Grid>
          {tags.map((tag: any, index: any) => (
            <Grid
              item
              xs={6}
              className={
                index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
              }
            >
              <InputLabel
                style={{ marginTop: "1em" }}
                classes={{ root: classes.labels }}
                htmlFor={tag.name}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {tag.name}
                  <div>
                    <DeleteIcon
                      style={{ marginLeft: "1em" }}
                      fontSize="small"
                      onClick={() => {
                        deleteProperty(index);
                      }}
                    ></DeleteIcon>
                    <EditIcon
                      style={{ marginLeft: "1em" }}
                      fontSize="small"
                      onClick={() => editFieldAction(index)}
                    ></EditIcon>
                  </div>
                </Grid>
              </InputLabel>
              <CssTextField
                fullWidth
                name={tag.name}
                type={tag.name}
                id={tag.name}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                onChange={(event) => handleChange(index, event)}
                value={tag.value}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container className={activeStep !== 0 ? classes.hidden : ""}>
          <Grid item xs={6} sm={2}>
            <div className={classes.rightSec}>
              <Button
                variant="contained"
                color="inherit"
                className={classes.listButton}
                endIcon={<AddIcon />}
                size="large"
                onClick={() => setOpen(true)}
              >
                Add New Field
              </Button>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          className={activeStep !== 1 ? classes.hidden : classes.appContainer}
        >
          <div className={classes.appTitle}>
            <Typography className={classes.colorWhite}>
              Application Levels *
            </Typography>
          </div>
          <Grid item xs={6} sm={2}>
            <Button
              variant="contained"
              color="inherit"
              className={classes.listButtonNew}
              endIcon={<AddIcon />}
              size="large"
              onClick={() => addLevels()}
            >
              Create Application Level
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} container>
            {levels.length > 0 && (
              <div className={classes.levelBlock}>
                <StyledTableCell>
                  <span> Level </span>
                </StyledTableCell>
                <div>
                  <input
                    type="search"
                    className={classes.levelSearch}
                    placeholder="Search..."
                    onChange={(event) => handleChangeSearch(event)}
                  />
                </div>
                {levels
                  .filter((item: any) =>
                    item.level_name
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((item: any, index: number) => (
                    <StyledTableCell>
                      <span
                        className={
                          index === currState ? classes.activelevel : ""
                        }
                        onClick={onSelectColorChange(index)}
                      >
                        {item.level_name}
                      </span>
                      <IconButton
                        onClick={onSelectMenuItem(index)}
                        classes={{ root: classes.menuButtonRoot }}
                      >
                        <MoreVertIcon color="secondary" />
                      </IconButton>
                      <Menu
                        id="user-menu"
                        anchorEl={anchorEl1}
                        keepMounted
                        open={menu[index]}
                        onClose={handleClose}
                        classes={{ paper: classes.menuRoot }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          className={classes.menuitem}
                          onClick={() => onEditLevel(index, "level")}
                        >
                          <EditIcon fontSize="small"></EditIcon>
                          Edit
                        </MenuItem>
                        <MenuItem
                          className={classes.menuitem}
                          onClick={() => deleteSubLevel(index, "level")}
                        >
                          <DeleteIcon fontSize="small"></DeleteIcon>
                          Delete
                        </MenuItem>
                      </Menu>
                    </StyledTableCell>
                  ))}
              </div>
            )}
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          classes={{ paper: classes.dialogRoot }}
          onClose={() => setOpenEdit(false)}
          aria-labelledby="customized-dialog-title"
          open={openedit}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setOpenEdit(false)}
          >
            Edit Field
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <form className={classes.formContent}>
              <div>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <Grid item xs={10} className={classes.spaceLeft}>
                    <CssTextField
                      fullWidth
                      name="name"
                      label="Field Name"
                      type="name"
                      id="name"
                      className={classes.fieldWrapper}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labels,
                      }}
                      onChange={(event) => setEditFieldName(event.target.value)}
                      value={editfield}
                    />
                  </Grid>
                </Grid>
              </div>
            </form>
          </DialogContent>
          <ThemeProvider theme={theme1}>
            <Divider
              variant="middle"
              classes={{ middle: classes.dividerColor }}
            />
          </ThemeProvider>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenEdit(false);
                setEditFieldName("");
              }}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => saveEditField()}
              variant="contained"
              className="dcBtEdit"
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          classes={{ paper: classes.dialogRoot }}
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setOpen(false)}
          >
            Add New Field
          </DialogTitle>
          <DialogContent style={{ overflowY: "auto", overflowX: "hidden" }}>
            <form
              className={classes.formContent}
              onSubmit={handleSubmitNewFields}
            >
              {addFieldInput.map((inputField, index) => (
                <div>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Grid item xs={10} className={classes.spaceLeft}>
                      <CssTextField
                        fullWidth
                        name="name"
                        label="Field Name *"
                        type="name"
                        id="name"
                        className={classes.fieldWrapper}
                        InputProps={{
                          disableUnderline: true,
                          onChange: (event) =>
                            onChangeAddNewFields(index, event),
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labels,
                        }}
                        value={inputField.name}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button onClick={() => handleDeleteNewFields(index)}>
                        <DeleteIcon style={{ color: "#FFFFFF" }}></DeleteIcon>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Button
                variant="contained"
                color="inherit"
                className={classes.listButton}
                endIcon={<AddIcon />}
                size="large"
                onClick={() => onAddNewFields()}
              >
                Add New Field
              </Button>
            </form>
          </DialogContent>

          <ThemeProvider theme={theme1}>
            <Divider
              variant="middle"
              classes={{ middle: classes.dividerColor }}
            />
          </ThemeProvider>
          <DialogActions>
            <Button
              onClick={onCancelAddNewField}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitNewFields}
              variant="contained"
              className="dcBtEdit"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          classes={{ paper: classes.dialogRoot }}
          onClose={() => setOpenAppLevel(false)}
          aria-labelledby="customized-dialog-title"
          open={appLevel}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setOpenAppLevel(false)}
          >
            Add Level Details
            <p
              style={{
                fontSize: "10px",
                marginBottom: "-2em",
                color: "lightgray",
              }}
            >
              * Seperate each level with a pipe character(|)
            </p>
            <p
              style={{
                fontSize: "10px",
                marginBottom: "-2em",
                color: "lightgray",
              }}
            >
              E.g.: L1|L2|L3|L4|L5
            </p>
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden", overflowY: "auto" }}>
            <form className={classes.formContent}>
              {addLevel.map((input, index) => (
                <div>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    className={classes.fieldWrapper}
                    justifyContent="space-around"
                  >
                    <Grid item xs={10} className={classes.spaceLeft}>
                      <CssTextField
                        fullWidth
                        name="levelName"
                        label="Level Name *"
                        type="levelName"
                        id="indexOfLevel"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labels,
                        }}
                        onChange={(event) => onChangeLevel(index, event)}
                        value={input.level_name}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {/* given a marginTop of 32 to accomodate the left element margins */}
                      <Button style={{marginTop:"32px"}} onClick={() => onDeleteLevel(index)}>
                        <DeleteIcon style={{ color: "#FFFFFF" }}></DeleteIcon>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Button
                variant="contained"
                color="inherit"
                className={classes.listButton}
                endIcon={<AddIcon />}
                size="large"
                onClick={() => onAddLevelField()}
              >
                Add New Level
              </Button>
            </form>
          </DialogContent>
          <ThemeProvider theme={theme1}>
            <Divider
              variant="middle"
              classes={{ middle: classes.dividerColor }}
            />
          </ThemeProvider>
          <DialogActions>
            <Button
              onClick={() => setOpenAppLevel(false)}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmitLevel}
              variant="contained"
              className="dcBtEdit"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          classes={{ paper: classes.dialogRoot }}
          onClose={() => setAppEditLevel(false)}
          aria-labelledby="customized-dialog-title"
          open={appEditLevel}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setAppEditLevel(false)}
          >
            Edit Level Details
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <form className={classes.formContent}>
              {editLevelName.map((row: any, index: number) => (
                <div>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Grid item xs={10} className={classes.spaceLeft}>
                      <CssTextField
                        fullWidth
                        name="level_name"
                        label="Level Name"
                        type="levelName"
                        id="indexOfLevel"
                        className={classes.fieldWrapper}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labels,
                        }}
                        onChange={(event) => onChangeEditLevel(index, event)}
                        value={row.level_name}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))}
            </form>
          </DialogContent>

          <ThemeProvider theme={theme1}>
            <Divider
              variant="middle"
              classes={{ middle: classes.dividerColor }}
            />
          </ThemeProvider>
          <DialogActions>
            <Button
              onClick={() => setAppEditLevel(false)}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmitEditLevel}
              variant="contained"
              className="dcBtEdit"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={activeStep === 0 ? onCancel : handleBack}
          >
            {activeStep === 0 ? "Cancel" : "Back"}
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNUser}
            onClick={
              activeStep === steps.length - 1 ? saveApplication : handleNext
            }
          >
            {activeStep === steps.length - 1 ? "Save Application" : "Next"}
          </Button>
        </div>
      </form>
      <Backdrop
        className={classes.backdrop}
        open={backDrop}
        onClick={backDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <ErrorDialog open={error} message={errMsg} updateError={updateError} />
      )}
    </div>
  );
};

interface ChildComponentProps {
  clickCancel: any;
}

export default function AddNewApplication({
  clickCancel,
}: ChildComponentProps) {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <AddNewApplicationWrapper clickCancel={clickCancel} />
    </SnackbarProvider>
  );
}
