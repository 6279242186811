import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  CustomButton,
  LoadingIndicator,
  SubmitButton,
} from "../../../../../UiComponents";
import useApi from "../../../../../../hooks/useApi";
import validateNativeTags from "../../../utils/validateNativeTags";
import useStyles from "./Submit.styles";

const tthex = /^[0-9a-fA-F]{32}$/;
const sthex = /^[0-9a-fA-F]{16}$/;
const letterNumber = /^[0-9a-zA-Z_-\s]*$/;
const letterNumberID = /^[0-9a-zA-Z_-]*$/;

const validateAlphaNumeric = (value: string) =>
  !value || !letterNumber.test(value) || value.length > 50 || value.trim() === "";

const validateConnectionConfiguration = (deviceData: any) => {
  if (
    deviceData.conn_config &&
    deviceData.conn_config?.metadata.config_type === "LORIOT"
  ) {
    const { appeui, appkey, description, devclass, deveui, title } =
      deviceData.conn_request_payload;

    if (title === "") {
      return "Please enter device title for LORIOT";
    } else if (description === "") {
      return "Please enter device description for LORIOT";
    } else if (devclass === "") {
      return "Please select device class";
    } else if (appkey === "" || !tthex.test(appkey)) {
      return "Please enter valid App Key (32 character HEX)";
    } else if (appeui === "" || !sthex.test(appeui)) {
      return "Please enter valid App EUI (16 character HEX)";
    } else if (deveui === "" || !sthex.test(deveui)) {
      return "Please enter valid Device EUI (16 character HEX)";
    } else if (deveui.toUpperCase() !== deveui) {
      return "Please enter Device EUI in upper case";
    }
  }
  return "";
};

const validateData = (deviceData: any) => {
  if (
    (deviceData.device_id || "").trim() === "" ||
    !letterNumberID.test(deviceData.device_id) ||
    deviceData.device_id.length > 64
  ) {
    return "Invalid Device ID (only 0-9,A-Z,a-z,_,- allowed upto 64 characters)";
  }

  if (validateAlphaNumeric(deviceData.device_name)) {
    return "Invalid Device Name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)";
  }
  if (validateAlphaNumeric(deviceData.serial_num)) {
    return "Invalid Serial (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)";
  }
  const connectionConfigurationValidationResult =
    validateConnectionConfiguration(deviceData);

  if (connectionConfigurationValidationResult) {
    return connectionConfigurationValidationResult;
  }

  const tagValidationResult = validateNativeTags(deviceData.device_config.tags);

  if (tagValidationResult) {
    return tagValidationResult;
  }

  return "";
};

const mapData = (formValues: any) => {
  let mappedData: any = {
    device: {
      application_level_id: formValues.application_level_id,
      device_id: formValues.device_id,
      device_name: formValues.device_name,
      serial_num: formValues.serial_num,
      device_config_id: formValues.device_config_id,
      device_config: {
        config_name: formValues.device_config.config_name,
        device_type: formValues.device_config.device_type,
        auth_type: formValues.device_config.auth_type,
        edge_type: formValues.device_config.edge_type,
        add_to_iot_hub: formValues.device_config.add_to_iot_hub,
        properties: formValues.device_config.properties,
        tags: formValues.device_config.tags,
      },
      device_install_status_id: formValues.device_install_status_id,
      cloud_gateway: formValues.cloud_gateway,
      fields: formValues.fields,
      user_action: "UPDATE_DEVICE", //TODO: this parameter is not used in backend.
    },
  };

  if (formValues.conn_config) {
    mappedData = {
      ...mappedData,
      device: {
        ...mappedData.device,
        conn_config: formValues.conn_config,
        conn_config_id: formValues.conn_config_id,
        conn_request_payload: formValues.conn_request_payload,
      },
    };
  }
  return mappedData;
};

const Submit: React.FC<any> = ({
  application,
  deviceDlmId,
  onDone,
  formValues,
  disabled,
}) => {
  const classes = useStyles();

  const {
    data: editResponse,
    status: editRequestStatus,
    trigger: submitData,
  } = useApi(`/applications/${application}/devices/${deviceDlmId}`, {
    method: "PUT",
    deferred: true,
    includePMSCredentialsInRequestBody: true,
    // mock: { fetcher: () => ({ message: "success" }) },
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if ((editResponse?.message || "").toLowerCase() === "success") {
      enqueueSnackbar("Device modification initiated", { variant: "success" });
      onDone();
    }
    if (editRequestStatus.error) {
      enqueueSnackbar("Device modification failed", {
        variant: "error",
      });
    }
  }, [editResponse, editRequestStatus.error, enqueueSnackbar, onDone]);

  return (
    <div className={classes.buttonWrapper}>
      {editRequestStatus.pending && <LoadingIndicator />}
      <CustomButton variant="outlined-white" onClick={onDone}>
        Cancel
      </CustomButton>
      <SubmitButton
        disabled={editRequestStatus.pending || disabled}
        onClick={() => {
          const data = mapData(formValues);
          const error = validateData(data.device);
          if (error) {
            return enqueueSnackbar(error, { variant: "error" });
          }
          submitData(data);
        }}
      >
        Save Device
      </SubmitButton>
    </div>
  );
};

export default Submit;
