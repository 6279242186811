import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import deleteLogo from "../../assets/Delete.png";
import CloseIcon from "@material-ui/icons/Close";
import { API } from "../../api/property";
import { SnackbarProvider, useSnackbar } from "notistack";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { isAfter } from "date-fns";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ErrorDialog from "../../utils/ErrorDialog";
import PermissionDialog from "./UsersComponent/PermissionDialog";
import AddNewUserStepOne from "./UsersComponent/AddNewUserStepOne";
import AddNewUserStepTwo from "./UsersComponent/AddNewUserStepTwo";
import callXhrRequest from '../../utils/xhrRequestHandler';
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import { Checkbox } from "@material-ui/core";
import { emailRegex } from "../../utils/constant";
const textFieldBorder = "1px solid #464659";
const buttonBorder = "1px solid rgba(235,235,245,0.35)";
const stepsColor = "red !important";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    hidden: {
      display: "none",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeftSite: {
      display: "flex",
      alignItems: "flex-end",
      paddingLeft: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    formTitle: {
      fontWeight: "bold",
      fontSize: "21px",
      color: "#FFFFFF",
      lineHeight: "25px",
    },
    form: {
      width: "100%",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      fontSize: "16px",
      transform: "scale(1)",
    },
    accordionRoot: {
      color: "#FFFFFF",
      margin: "0px",
      padding: "0px",
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      minHeight: "auto !important",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    datePickerRoot: {
      color: "#FFFFFF",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      "& .MuiIconButton-root, .MuiPickersDay-day, .MuiPickersCalendarHeader-dayLabel": {
        color: "#FFFFFF",
      },
      "& .MuiPickersDay-daySelected": {
        border: `${textFieldBorder}`,
      },
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: `${buttonBorder}`,
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNUser: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcOuterPadding": {
        padding: "10px 20px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        margin: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: `${buttonBorder}`,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    bgColor: {
      background: "#2B2B36",
      margin: "1px 0",
      padding: "7px 0",
    },
    marginLeft15: {
      marginLeft: "-1px",
    },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    helperText: {
      color: "#d32f2f !important",
      position: "absolute",
      right: 0,
    },
    isExpand: {
      position: "relative",
      top: "1rem",
    },
    stepperRoot: {
      width: "50%",
      margin: "0 auto",
    },
    stepname: {
      color: "#FFF",
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },
    stepactive: {
      color: "#FFF",
    },
    steplabel: {
      color: "#FFF",
      "& span": {
        color: "darkgray !important",
      },
      "& .active": {
        color: "green !important",
      },
    },
    step: {
      "&$completed": {
        color: "#0089ff",
      },
      "&$active": {
        color: "cornflowerblue",
      },
      "&$disabled": {
        color: "red",
      },
    },
    alternativeLabel: {
      color: "yellow !important",
      "&$active": {
        color: `${stepsColor}`,
      },
    },
    active: {
      color: "cornflowerblue !important",
    },
    completed: {
      color: "#0089ff !important",
    },
    disabled: {
      color: "red",
    },
    labelContainer: {
      color: `${stepsColor}`,
      "&$alternativeLabel": {
        marginTop: 0,
        color: "orange !important",
      },
      "&$active": {
        color: `${stepsColor}`,
      },
      "&$completed": {
        color: "green !important",
      },
    },
    appContainer: {
      padding: "1rem",
      background: "#2b2b36",
      marginTop: "1rem",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "13px",
        padding: "10px 25px",
        backgroundColor: "#2B2B36",
        color: "white",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "1px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: `${buttonBorder}`,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    appTitle: {
      width: "100%",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
      overflow: "hidden",
      width: "50em",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    btnDelRole: {
      textDecoration: "underline",
      color: "#0089ff !important",
    },
    textField: {
      width: "26ch",
      color: "#fff",
      padding: "3px 10px",
      border: "1px solid gray",
    },
    margin: {
      margin: theme.spacing(2),
    },
    levelSearch: {
      height: "50px",
      backgroundColor: "#2B2B36",
      border: "transparent",
      color: "white",
      marginTop: "2em",
      paddingLeft: "23px"
    },
  })
);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);
const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });
export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 20px",
    padding: 0,
    color: "#FFFFFF",
    scrollY: "auto",
  },
}))(MuiDialogContent);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#1D1D25",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "3px 0",
      background: "#1D1D25",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
      },
    },
  })
)(TableRow);

function getSteps() {
  return ["User Details", "Roles & Permissions"];
}

interface ComponentProps {
  onSaveUser: any;
}

const getDefaultValidToDate = () => {
  let validToDate = new Date();
  validToDate.setFullYear(validToDate.getFullYear() + 1)
  return validToDate;
}

const AddNewUserWrapper: FC<ComponentProps> = (props: ComponentProps) => {
  const classes = useStyles();
  const { user } = useStore();

  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const [userEmailValidation, setUserEmailValidation] = useState([] as any);

  const [application_level_id, setApplication_level_id] = useState([] as any);
  const [application_level_name, setApplication_level_name] = useState([] as any);

  const [userApplication, setUserApplication] = useState([] as any);
  const [app, setApp] = useState("");
  const [applvl, setAppLevel] = useState(false);
  const [reload, setReload] = useState(false);
  const handleChangeApp = (event: ChangeEvent<HTMLInputElement>) => {
    setApp(event.target.value);
    setAppLevel(true);
    setUserChoices("");
    setApplication_level_id([]);
    setApplication_level_name([]);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [emailHelper, setEmailHelper] = useState("");
  const [email, setEmail] = useState("");

  const onEmailChange = (event: any) => {
    if(emailRegex.test(event.target.value)) {
      setEmailHelper("");
    } else {
      setEmailHelper("Please enter valid email address");
    }
    setEmail(event.target.value);
  };
  const [search, setSearch] = useState("");
  const handleChangeSearch = (event: any) => {
    const target = event.target.value;
    setSearch(target);
  };

  const [rolesData, setRolesData] = useState<any>({});

  const [roles, setRoles] = useState("" as any);
  const handleChangeRoles = (event: any) => {
    setRoles(event.target.value);
    const _rolesData =
      (selectedApplication.roles || []).find(
        (role: any) => role.role_id === event.target.value
      ) || {};
    setRolesData(_rolesData);
  };
  const [permDialog, setPermDialogOpen] = useState(false);

  const openPermDialog = () => {
    setPermDialogOpen(true);
  };

  const dialogPermClose = () => {
    setPermDialogOpen(false);
  };
  const [rolesInput, setRolesInput] = useState([] as any);
  const [validToDate, setValidToDate] = useState<Date | null>(getDefaultValidToDate());
  const [validFromDate, setValidFromDate] = useState<Date | null>(new Date());
  const validToDateChange = (date: Date | null) => {
    setValidToDate(date);
  };
  const validFromDateChange = (date: Date | null) => {
    setValidFromDate(date);
  };
  const highlightHandler = (event: ChangeEvent<HTMLInputElement>, level_id: any, level_name: any) => {
    const checked = event.target.checked;
    let temp = [...application_level_id]
    let temp1 = [...application_level_name]
    if (checked) {
      temp.push(level_id)
      temp1.push(level_name)
    }
    else {
      temp.splice(temp.indexOf(level_id), 1);
      temp1.splice(temp.indexOf(level_name), 1);
    }
    setApplication_level_id(temp);
    setApplication_level_name(temp1);

  };

  const d = new Date(`${validToDate}`);
  const dateValidTo =
    d.getFullYear() +
    "-" +
    `${("0" + (d.getMonth() + 1)).slice(-2)}` +
    "-" +
    d.getDate() +
    " " +
    "23:59:59"
  const d1 = new Date(`${validFromDate}`);
  const dateValidFrom =
    d1.getFullYear() +
    "-" + 
    `${("0" + (d1.getMonth() + 1)).slice(-2)}` +
    "-" +
    d1.getDate() +
    " " +
    "00:00:01"

  const saveUserAction = () => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const USERS_API = `${API["GETALLAPPLS"]}/${app}/users`;
    if (!roles) {
      enqueueSnackbar("Please select a role.", { variant: "error" });
    } else if (!dateValidFrom) {
      enqueueSnackbar("Please select valid from date.", { variant: "error" });

    } else if (!dateValidTo) {
      enqueueSnackbar("Please select a valid to date.", { variant: "error" });
    } else if (isAfter(new Date(dateValidFrom),new Date(dateValidTo))) {
      enqueueSnackbar("'Valid from' should not be greater than 'Valid to' date", {
        variant: "error",
      });
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
        body: JSON.stringify({
          pm_s_id: pmSId,
          pm_s_token: pmST,
          user: {
            email_id: email,
            data: [
              {
                level_id: application_level_id,
                role_id: roles,
                valid_from: dateValidFrom,
                valid_to: dateValidTo,
                application_id: app
              }
            ]
          },
        }),
      };
      fetch(USERS_API, requestOptions).then(function (response: any) {
        response.json().then((response: any) => {
          if (response && response[0] && response[0].status === 401) {
            const responseMsg = response[0].message;
            if (arrayOfErrorsRefresh.includes(responseMsg.toLowerCase())) {
              try {
                const tokenKey = "sso_token";
                callXhrRequest().then(function (data) {
                  sessionStorage.setItem(tokenKey, data);
                  saveUserAction()
                }).catch(function (error) {
                  console.log('error', error)
                })
              } catch (error) {
                user.triggerLogout();
                console.log(error);
              }
              return;
            } else if (arrayOfErrorsLogout.includes(responseMsg.toLowerCase())) {
              try {
                user.triggerLogout();
              } catch (error) {
                console.log(error);
              }
              return;
            } else {
              enqueueSnackbar(responseMsg, { variant: "error" });
            }
            return;
          }
          else if (response.status === 400 ) {
            enqueueSnackbar(response.message, { variant: "error" });
          }
          else if (response.status === 200 || response.status === 201) {
            enqueueSnackbar("User Created", { variant: "success" });
            setTimeout(() => { cancelUserAction() }, 500);
          }
          else {
            setError(true);
            setErrMsg("Error occured. Please try again after sometime.");
          }
        })
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  };

  const cancelUserAction = () => {
    setTimeout(function () {
      setEmail("");
      setRoles("");
      setValidFromDate(new Date());
      setValidToDate(getDefaultValidToDate());
      setApp("");
      setActiveStep(0);
      setUserChoices([]);
      setAppLevel(false);
      setApplication_level_id([]);
      setApplication_level_name([]);
      props.onSaveUser();
    }, 5);
  };

  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const updateError = () => {
    setError(false);
    setErrMsg("");
  };
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = (event: any) => {
    event.preventDefault();
    const userList = userEmailValidation.filter((e:any)=>e.application_id === app);
    const uniqueEmailID = userList.some((e: any) => e.email_id.toLowerCase() === email.toLowerCase())
    if (!email) {
      enqueueSnackbar("Please enter email id.", { variant: "error" });
    } else if (!(emailRegex.test(email))) {
      enqueueSnackbar('Invalid email id.', { variant: "error" });
    } else if (uniqueEmailID) {
      enqueueSnackbar("Email id is already registered. Please enter another email id.", { variant: "error" });
    } else if (!app) {
      enqueueSnackbar("Please select an application.", { variant: "error" });
    } else if (application_level_id.length === 0) {
      enqueueSnackbar("Please select an application level.", { variant: "error" });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const dialogHandleDelete = () => {
    setOpenDialogDelete(true);
  };
  const dialogHandleCloseDelete = () => {
    setOpenDialogDelete(false);
  };
  const [userChoices, setUserChoices] = useState<any>([]);
  const userChoicesValue: any = [];
  const [dummyRoles, setDummyRoles] = useState([...userChoicesValue]);

  const dialogHandlesaveDelete = () => {
    dummyRoles.splice(-1, 1);
    setDummyRoles(dummyRoles);
    setTimeout(function () {
      enqueueSnackbar("User Updated", { variant: "success" });
    }, 1000);
    setOpenDialogDelete(false);
    setTimeout(function () {
      setOpenDialogDelete(false);
    }, 500);
  };
  const selectedApplication =
    userApplication ? (userApplication.find((a: any) => a.application_id === app) || {}) : {};
  const levels = selectedApplication.application_level || [];
  useEffect(() => {
    setDummyRoles(userChoices);
  }, [userChoices]);

  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sObj {
      pm_s_id: string;
    }
    interface tObj {
      pm_s_token: string;
    }
    let pmSId: sObj = {
      pm_s_id: "",
    };
    let pmST: tObj = {
      pm_s_token: "",
    };
    pmSId =
      sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
    pmST =
      sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
    const GETALLUSERS_API = API["GETALLUSERS"];
    const GETUSERSAPPLS_API = API["GETUSERSAPPLS"];
    const GETROLES_INPUT = API["ROLES_APPLS_INPUT"];

    Promise.all([
      fetch(`${GETALLUSERS_API}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      }),
      fetch(`${GETUSERSAPPLS_API}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      }),
      fetch(`${GETROLES_INPUT}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      }),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response: any) {
        if ((response && response[0].status === 401) && (response && arrayOfErrorsRefresh.includes(response[0].message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              setReload(!reload)
            }).catch(function (error) {
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response[0].status === 401) && (response && arrayOfErrorsLogout.includes(response[0].message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        if (
          response &&
          response[0].status === 200 &&
          typeof response[0].users !== "undefined"
        ) {
          setUserEmailValidation(response && response[0].users);
        }
        if (
          response &&
          response[1].status === 200 &&
          typeof response[1].users_input !== "undefined"
        ) {
          setUserApplication(
            response && response[1].users_input[0].applications
          );
        }
        if (
          response &&
          response[2].status === 200 &&
          typeof response[2].roles_input !== "undefined"
        ) {
          setRolesInput(response && response[2].roles_input[0].portal_components);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [API_KEY, reload]);

  return (
    <div className={classes.root}>
      <div className={classes.stepperRoot}>
        <Stepper activeStep={activeStep} className={classes.stepactive}>
          {steps.map((label, index) => {
            return (
              <Step key={label} className={classes.stepname}>
                <StepLabel
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    labelContainer: classes.labelContainer,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.step,
                      completed: classes.completed,
                      active: classes.active,
                    },
                  }}
                  className={classes.steplabel}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {/* <p style={{ color: "#FFFFFF", fontSize: "12px" }}>All fields marked * are mandatory.</p> */}
      <form className={classes.form} noValidate method="post">
        {activeStep === 0 && (
          <AddNewUserStepOne
            classes={classes}
            onChangeHelperText={onEmailChange}
            onChangeApplication={handleChangeApp}
            applications={userApplication}
            helperText={emailHelper}
            currentApp={app}
            userEmail={email}
          />
        )}
        {activeStep === 1 && (
          <AddNewUserStepTwo
            classes={classes}
            dialogHandleDelete={dialogHandleDelete}
            onChangeRoles={handleChangeRoles}
            showDialog={openPermDialog}
            onValidToDateChange={validToDateChange}
            onValidFromDateChange={validFromDateChange}
            dateValidTo={validToDate}
            dateValidFrom={validFromDate}
            userChoices={userChoices}
            roles={roles}
            currentApp={selectedApplication}
            applicationLevels={application_level_name}
          />
        )}
        <Grid
          container
          className={
            applvl
              ? activeStep !== 1
                ? classes.appContainer
                : classes.hidden
              : classes.hidden
          }
        >
          <div className={classes.appTitle}>
            <Typography className={classes.colorWhite}>
              Application Levels *
            </Typography>
          </div>
          <Grid item xs={12} sm={12} container>
            <TableContainer
              component={Paper}
              className={classes.tableWrapper}
            >
              <Table
                className={classes.table}
                aria-label="customized table"
              >
                <TableHead>
                </TableHead>
                <TableHead style={{ color: "#FFFFFF" }} >
                  <input type="search" className={classes.levelSearch} placeholder="Search..." onChange={(event) => handleChangeSearch(event)} />
                </TableHead>
                <TableBody>
                  {levels.filter((item: any) => item.level_name.toLowerCase().includes(search.toLowerCase())).map((row: any, index: number) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Checkbox
                          className={classes.bgColorBlue}
                          aria-label="level"
                          value={row.level_name}
                          onChange={(event) => { highlightHandler(event, row.level_id, row.level_name) }}
                          checked={application_level_id.includes(row.level_id)}
                        />

                        {row.level_name}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={activeStep === 0 ? cancelUserAction : handleBack}
          >
            {activeStep === 0 ? "Cancel" : "Back"}
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNUser}
            onClick={
              activeStep === steps.length - 1 ? saveUserAction : handleNext
            }
          >
            {activeStep === steps.length - 1 ? "Save User" : "Next"}
          </Button>
        </div>

        <Dialog
          classes={{ paper: classes.dialogRootSmall }}
          onClose={dialogHandleCloseDelete}
          aria-labelledby="customized-dialog-title"
          open={openDialogDelete}
        >
          <Grid container direction="row" alignItems="center">
            <img
              style={{ marginLeft: "1em" }}
              alt="Delete Logo"
              src={deleteLogo}
            ></img>
            <DialogTitle
              id="customized-dialog-title"
              onClose={dialogHandleCloseDelete}
            >
              Delete Role
            </DialogTitle>
          </Grid>
          <DialogContent>
            <Typography
              style={{
                fontFamily: "Avenir Next",
                fontSize: "14px",
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                margin: "1em",
                color: "#FFFFFF",
              }}
            >
              Are you sure you want to delete this Role? This Role cannot be
              recovered.
            </Typography>
          </DialogContent>
          <Divider
            variant="middle"
            classes={{ middle: classes.dividerColor }}
          />

          <DialogActions>
            <Button
              onClick={dialogHandleCloseDelete}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={dialogHandlesaveDelete}
              variant="contained"
              className="dcBtEdit"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <PermissionDialog
          isOpen={permDialog}
          onClose={dialogPermClose}
          classes={classes}
          permissionChecked={rolesData.permission_mapping}
          checkboxAvailable={rolesInput}
        />
      </form>
      {error && (
        <ErrorDialog open={error} message={errMsg} updateError={updateError} />
      )}
    </div>
  );
};

interface ChildComponentProps {
  onSaveUser: any;
}

export default function AddNewUser({ onSaveUser }: ChildComponentProps) {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <AddNewUserWrapper onSaveUser={onSaveUser} />
    </SnackbarProvider>
  );
}