import React from "react";
import { Grid } from "@material-ui/core";

import TextField from "./TextField";
import { ICustomTextFieldProps } from "./TextField/types";

const GriddedTextField: React.FC<ICustomTextFieldProps> = (props) => {
  return (
    <Grid item xs={6}>
      <TextField {...props} />
    </Grid>
  );
};

export default GriddedTextField;
