import React from "react";
import {
  DropDown,
  CustomTextField,
  CustomCheckbox,
  CustomInputLabel,
} from "../../../../UiComponents";
import getKeysFromEnum from "../../../../Devices/DeviceManagement/AddDevice/BulkUpdate/utils/getKeysFromEnums";
import { TAG_DATA_TYPE, TAG_TYPE } from "../../../../../utils/constant";
import { IconButton } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import useStyles from "./AddNewTag.styles";

const TAG_TYPE_OPTIONS = getKeysFromEnum(TAG_TYPE).map((k) => ({
  label: k,
  value: k,
}));

const TAG_DATA_TYPE_OPTIONS = getKeysFromEnum(TAG_DATA_TYPE).map((k) => ({
  label: k,
  value: k,
}));

const TagForm: React.FC<any> = ({
  name,
  data_type,
  type,
  is_visible_as_column,
  onChange,
  onRemove,
  disableDelete,
}) => {
  const classes = useStyles();
  return (
    <div style={{ display: "flex" }}>
      <CustomTextField
        label="Tag Name*"
        value={name}
        onChange={(v) => onChange("name", v)}
      />
      <DropDown
        options={TAG_TYPE_OPTIONS}
        label="Field Type*"
        value={type}
        onChange={(v) => onChange("type", v)}
      />
      <DropDown
        disabled={type === TAG_TYPE.Tabular}
        options={TAG_DATA_TYPE_OPTIONS}
        label="Data Type*"
        value={data_type}
        onChange={(v) => onChange("data_type", v)}
      />
      <div className={classes.checkboxWrapper}>
        <CustomInputLabel
          disabled={type === TAG_TYPE.Tabular}
          style={{ margin: "1.2em 0 1.5em 0" }}
        >
          Column View
        </CustomInputLabel>
        <CustomCheckbox
          readOnly={type === TAG_TYPE.Tabular}
          checked={is_visible_as_column}
          label=""
          name="is_visible_as_column"
          onChange={(checked: boolean) =>
            onChange("is_visible_as_column", checked)
          }
        />
      </div>

      <IconButton
        disabled={disableDelete}
        onClick={onRemove}
        style={{ margin: "1.2em 0 0 1em" }}
      >
        <DeleteIcon color="secondary" />
      </IconButton>
    </div>
  );
};

export default TagForm;
