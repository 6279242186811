import React, { useState } from "react";
import HeaderNavigation from "./HeaderNavigation";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { GriddedTextField } from "../../UiComponents";
import CustomTextField from "../../UiComponents/TextField/TextField";
import { TAG_TYPE } from "../../../utils/constant";
import readError from "../../../utils/readError";
import { Tags, Properties, TabularDropdowns, Submit } from "./formParts";
import ButtonText from "../../UiComponents/ButtonText";
import AddNewTag from "./formParts/AddNewTag";
import AddNewDropdownSpecifications from "./formParts/AddNewDropdownSpecifications";
import { validateAddedTags } from "./formParts/AddNewTag/utils";
import useStyles from "./EditConfiguration.styles";

interface IEditConfigurationProps {
  onEditConfig: any;
  editItem: any;
}

const prepareFields = (configuration: any) => {
  return {
    configurationName: configuration.config_name,
    configurationDescription: configuration.config_desc,
  };
};

const EditConfiguration: React.FC<IEditConfigurationProps> = ({
  onEditConfig,
  editItem,
}) => {
  const [fields, setFields] = useState(prepareFields(editItem));
  const [tags, setTags] = useState(editItem.tags);
  const [properties, setProperties] = useState(editItem.properties);
  const [dropdown, setDropdown] = useState(editItem.dropdown);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const safelyRemoveTag = (removedTagName: string) => {
    const { [removedTagName]: removedTagDetails, ...persistingTags } = tags;
    if (
      removedTagDetails.type === TAG_TYPE.Tabular &&
      dropdown[removedTagName] //user needs to remove the dropdown spec first
    ) {
      throw new Error(
        `Please remove all dropdown specification for the tag '${removedTagName}'`
      );
    }
    setTags(persistingTags);
  };

  return (
    <>
      <HeaderNavigation onClick={onEditConfig} />

      <div className={classes.wrapper}>
        {/* TODO: Move this to basicFormFields */}
        <Grid container>
          <GriddedTextField
            label=" Select Application *"
            name="selectApplication"
            value={editItem.application_name}
            readOnly
          />
          <GriddedTextField
            name="config"
            label="Configuration Name"
            value={fields.configurationName}
            onChange={(value: string) =>
              setFields({ ...fields, configurationName: value })
            }
            readOnly
          />
          <GriddedTextField
            name="deviceType"
            label="Device Type *"
            value={editItem.device_type}
            readOnly
          />

          <GriddedTextField
            name="edgeType"
            label="Edge Type *"
            value={editItem.edge_type}
            readOnly
          />

          <GriddedTextField
            name="authType"
            label="Auth Type *"
            value={editItem.auth_type}
            readOnly
          />

          <Grid item xs={12}>
            <CustomTextField
              name="configDesc"
              label="Configuration Description *"
              value={fields.configurationDescription}
              onChange={(value: string) =>
                setFields({ ...fields, configurationDescription: value })
              }
            />
          </Grid>
          <Tags
            tags={tags}
            onTagChange={(tagName: any, tagData: any) => {
              const { type } = tagData;
              //TODO: these checks needs to be extracted to a function
              if (type === "Dropdown") {
                const existingOptions = editItem.tags[tagName]
                  ? editItem.tags[tagName].options
                  : ""; //newly added tag

                // Check if there are no existing options
                if (!existingOptions || existingOptions.trim() === "") {
                  // Directly set the new options provided by the user
                  setTags({
                    ...tags,
                    [tagName]: { ...tagData },
                  });
                } else {
                  // If there are existing options

                  // Check if tagData.options starts with existing options and appends data after "|"
                  if (tagData.options.startsWith(existingOptions + "|")) {
                    setTags({ ...tags, [tagName]: { ...tagData } });
                  } else if (tagData.options === existingOptions) {
                    //removes appended value along with "|"
                    if (existingOptions.endsWith("|")) {
                      const newOptions = existingOptions.slice(0, -1); // Remove the "|" symbol
                      setTags({
                        ...tags,
                        [tagName]: { ...tagData, options: newOptions },
                      });
                    } else {
                      // removes appended value but there's no "|" symbol to remove
                      setTags({ ...tags, [tagName]: tagData });
                    }
                  } else {
                    setTags(tags);
                    enqueueSnackbar(
                      "Cannot edit previous values. Append data only after adding | symbol.",
                      {
                        variant: "error",
                      }
                    );
                  }
                }
              } else if (type === "Text" || type === "Tabular") {
                setTags({ ...tags, [tagName]: tagData });
              } else {
                // Display error message for other types
                enqueueSnackbar("Cannot edit previous values", {
                  variant: "error",
                });
              }
            }}
            onTagDelete={(removedTagName: any) => {
              try {
                safelyRemoveTag(removedTagName);
              } catch (error) {
                const errorMessage = readError(error);
                enqueueSnackbar(errorMessage, { variant: "error" });
              }
            }}
          />
          <AddNewTag
            onTagAdd={(newTags: any) => {
              //Note: Errors are handled inside component
              validateAddedTags(tags, newTags);
              setTags({ ...tags, ...newTags });
            }}
          />
          <TabularDropdowns
            dropdowns={dropdown}
            newlyAddedTabularTags={Object.keys(tags).filter(
              (t) => tags[t]._isNew
            )}
            onDropdownUpdate={(
              tableName: string,
              columnName: string,
              value: string
            ) => {
              const updatedTableOptions = {
                ...dropdown[tableName],
                [columnName]: value,
              };
              setDropdown({ ...dropdown, [tableName]: updatedTableOptions });
            }}
            onDropdownRemove={(
              tableName: string,
              removedColumnName: string
            ) => {
              const { [removedColumnName]: _, ...persistingTableOptions } =
                dropdown[tableName];

              //if all column specs are removed for the tag, then remove the tag from dropdown
              if (Object.keys(persistingTableOptions).length > 0) {
                setDropdown({
                  ...dropdown,
                  [tableName]: persistingTableOptions,
                });
              } else {
                const { [tableName]: _, ...persitingTableMappings } = dropdown;
                setDropdown(persitingTableMappings);
              }
            }}
          />
          <AddNewDropdownSpecifications
            tags={tags}
            onDropdownSpecificationAdd={(
              tabularTagName: string,
              selectedColumn: string
            ) => {
              if (dropdown[tabularTagName]?.[selectedColumn] !== undefined) {
                throw new Error(`Dropdown specification already exists!`);
              }
              const updatedTableOptions = {
                ...dropdown[tabularTagName],
                [selectedColumn]: "",
              };
              setDropdown({
                ...dropdown,
                [tabularTagName]: updatedTableOptions,
              });
            }}
          />

          <Properties
            properties={properties}
            onPropertyChange={(propertyName: string, propertyValue: string) => {
              setProperties({ ...properties, [propertyName]: propertyValue });
            }}
          />
          <ButtonText
            style={{ margin: "-1.2em 0 0 1em" }}
            label="Add New Property"
            disabled
          />
          <Submit
            configData={{
              existingData: editItem,
              updatedData: { fields, tags, properties, dropdown },
            }}
            onDone={onEditConfig}
          />
        </Grid>
      </div>
    </>
  );
};

export default EditConfiguration;
