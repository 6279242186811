import React, { useEffect, useState } from "react";
import { Grid, TableFooter, Menu, MenuItem, TableBody, Typography, TableContainer, TableHead, TableRow, Table, Paper } from "@material-ui/core";
import { useStyles, StyledTableRow, StyledTableSort, StyledTableCell } from './applicationStyles';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import TablePagination from "@material-ui/core/TablePagination";
import Pagination from "material-ui-flat-pagination";


interface ApplicationTableViewProps {
    customiseColumnCheck: any;
    appsData: any;
    search: string;
    handleClick: (e: any) => (e: any) => void;
    anchorEl: any;
    handleClose: (e: any) => () => void;
    handleEdit: (e: any) => void;
    handleView: (e: any) => void;
    dialogHandleDelete: (e: any) => void;
    menu: any;
}

const getFilteredItem = (items: any, search: string) => {
    const _search = search.toLowerCase();

    return items.filter((item: any) => {
        if (item.application_name && item.application_name.toLowerCase().includes(_search)) {
            return true
        }
        if (item.snow_req_id && item.snow_req_id.toLowerCase().includes(_search)) {
            return true
        }
        if (item.apex_id && item.apex_id.toLowerCase().includes(_search)) {
            return true
        }
        if (item.metadata && item.metadata.lob && item.metadata.lob.toLowerCase().includes(_search)) {
            return true
        }
        return false
    })
}
const getSortedItem = (sortBy: string, sortOrder: string, selectedApplication: any) => {
    const itemsToSort = JSON.parse(JSON.stringify(selectedApplication));
    const compareFn = (i: any, j: any) => {
        if (i[sortBy] < j[sortBy]) {
            return sortOrder === "asc" ? -1 : 1;
        } else {
            if (i[sortBy] > j[sortBy]) {
                return sortOrder === "asc" ? 1 : -1;
            } else {
                return 0;
            }
        }
    }
    return itemsToSort.sort(compareFn);
}

export default function ApplicationTableView({
    customiseColumnCheck,
    appsData,
    search,
    handleClick,
    handleClose,
    handleEdit,
    handleView,
    anchorEl,
    dialogHandleDelete,
    menu
}: ApplicationTableViewProps) {
    const classes = useStyles();
    const [defaultSortBy, setDefaultSortBy] = useState("application_name");
    const [defaultSortOrder, setDefaultSortOrder] = useState("asc");

    const [sortedItemRows, setSortItemRows] = useState(getSortedItem(defaultSortBy, defaultSortOrder, appsData));

    useEffect(() => {
        let rows = appsData;
        if (search.length) {
            setOffset(0);
            setPage(0);
            rows = getFilteredItem(appsData, search)
        }

        const sortedItems = getSortedItem(defaultSortBy, defaultSortOrder, rows);
        setSortItemRows(sortedItems);


    }, [defaultSortBy, defaultSortOrder, appsData, search])

    const sortByColumn = (columnName: any) => {
        if (columnName === defaultSortBy) {
            setDefaultSortOrder(defaultSortOrder === "asc" ? "desc" : "asc");
        } else {
            setDefaultSortOrder("asc");
            setDefaultSortBy(columnName)
        }
    }


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const onChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };
    const onChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [offset, setOffset] = useState(0);
    const onClickPaginate = (offset: number) => {
        setOffset(offset);
        setPage(offset / rowsPerPage);
    };
    
    return (
        <>
            {appsData.length > 0 ? (
        <TableContainer component={Paper} className={classes.tableWrapper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {customiseColumnCheck[0].checked ?
                            <StyledTableCell>
                                <StyledTableSort
                                    active={defaultSortBy === "application_name"}
                                    direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                                    onClick={() => sortByColumn("application_name")}
                                    IconComponent={ArrowDropDown}
                                    style={{ color: 'white' }}
                                >
                                    Application Name
                </StyledTableSort>
                            </StyledTableCell>
                            : (<div></div>
                            )}
                        {customiseColumnCheck[1].checked ?
                            <StyledTableCell>
                                <StyledTableSort
                                    active={defaultSortBy === "snow_req_id"}
                                    direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                                    onClick={() => sortByColumn("snow_req_id")}
                                    IconComponent={ArrowDropDown}
                                    style={{ color: 'white' }}
                                >
                                    Service Now ID
                </StyledTableSort></StyledTableCell>
                            : (<div></div>
                            )}
                        {customiseColumnCheck[2].checked ?
                            <StyledTableCell><StyledTableSort
                                active={defaultSortBy === "apex_id"}
                                direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                                onClick={() => sortByColumn("apex_id")}
                                IconComponent={ArrowDropDown}
                                style={{ color: 'white' }}
                            >
                                Apex ID
                </StyledTableSort></StyledTableCell>
                            : (<div></div>
                            )}
                        {customiseColumnCheck[3].checked ?
                            <StyledTableCell>LOB</StyledTableCell>
                            : (<div></div>
                            )}
                        <StyledTableCell>&nbsp;</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(sortedItemRows.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                    )).map((row: any, index: number) => (
                        <StyledTableRow key={row.application_id}>
                            {customiseColumnCheck[0].checked ?
                                <StyledTableCell component="th" scope="row">
                                    {row.application_name}
                                </StyledTableCell> : (
                                    <div></div>
                                )}
                            {customiseColumnCheck[1].checked ?
                                <StyledTableCell>{row.snow_req_id}</StyledTableCell> : (
                                    <div></div>
                                )}
                            {customiseColumnCheck[2].checked ?
                                <StyledTableCell>{row.apex_id}</StyledTableCell> : (
                                    <div></div>
                                )}
                            {customiseColumnCheck[3].checked ?
                                <StyledTableCell>
                                    {row.metadata !== null && row.metadata.lob}
                                </StyledTableCell> : (
                                    <div></div>
                                )}

                            <StyledTableCell>
                                <IconButton
                                    onClick={handleClick(index)}
                                    classes={{ root: classes.menuButtonRoot }}
                                >
                                    <MoreVertIcon color="secondary" />
                                </IconButton>
                                <Menu
                                    id={`user-menu-${row.application_id}`}
                                    anchorEl={anchorEl}
                                    open={menu[index]}
                                    onClose={handleClose(index)}
                                    classes={{ paper: classes.menuRoot }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <MenuItem
                                        data-id={index}
                                        className={`${classes.menuitem} ${classes.viewDetails}`}
                                        onClick={() => handleView(row)}
                                    >
                                        <VisibilityIcon></VisibilityIcon>
                          View Details
                        </MenuItem>
                                    <MenuItem
                                        data-id={index}
                                        className={`${classes.menuitem} ${classes.viewDetails}`}
                                        onClick={() => handleEdit(row)}
                                    >
                                        <EditIcon></EditIcon>
                          Edit
                        </MenuItem>
                                    <MenuItem
                                        data-id={index}
                                        className={`${classes.menuitem} ${classes.viewDetails}`}
                                        onClick={() => dialogHandleDelete(row.application_id)}
                                    >
                                        <DeleteOutlinedIcon></DeleteOutlinedIcon>
                          Delete
                        </MenuItem>
                                </Menu>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
                <TableFooter className={classes.tblfooter}>
                    <TableRow className={classes.tblfooter1}>
                    </TableRow>
                    <TableRow className={classes.tblfooter2}>
                    </TableRow>
                </TableFooter>
            </Table>
            </TableContainer>
            ) : (
                <div style={{ color: "white", padding: "25px" }}>
                    No Records Found.
                </div>
            )}
            <Grid container xs={12} direction='row' alignItems='center' justifyContent='space-between' style={{ paddingLeft: '1.7em'}}>
                <Grid xs={4} item container direction='row' alignItems='center'>
                    <Typography className={classes.paginateView}>
                        View:{" "}
                    </Typography>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={sortedItemRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={"Application per page :"}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                        className={classes.tblpaginate}
                    />
                    <Typography className={classes.paginateUser}>
                        Applications per page
                  </Typography>
                </Grid>
                <Pagination
                    limit={rowsPerPage}
                    offset={offset}
                    onClick={(e, offset) => onClickPaginate(offset)}
                    total={sortedItemRows.length}
                    className={classes.tblpaginatenumber}
                    currentPageColor="secondary"
                    otherPageColor="primary"
                />
            </Grid>
            </>
    )
}
