import React, { useState } from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  WithStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { createMuiTheme, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import deleteLogo from "../../assets/Delete.png";
import { API } from "../../api/property";
import { SnackbarProvider, useSnackbar } from "notistack";
import ErrorDialog from "../../utils/ErrorDialog";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import InputLabel from "@material-ui/core/InputLabel";
import EditIcon from "@material-ui/icons/Edit";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { DEFAULT_API_ERROR_MSG, INVALID_LEVEL_NAME_ERROR_MSG } from "../../utils/constant";
import { useApplicationContext } from "../../contexts/ApplicationContext";

const blueColor = "#0089FF";
const theme1 = createMuiTheme({
  palette: {
    primary: {
      main: `${blueColor}`,
    },
    secondary: {
      main: `#FFFFFF`,
    },
  },
});
const imprColor = "#0089ff !important";
const textFieldBorder = "1px solid #464659";
const spaceMargin = "0px !important";
const buttonBorder = "1px solid rgba(235,235,245,0.35)";
const stepsColor = "red !important";

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>

  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    spaceBottom: {
      marginBottom: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    formTitle: {
      fontWeight: "bold",
      color: "#FFFFFF",
      fontSize: "21px",
      lineHeight: "25px",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "19px",
      fontSize: "16px",
      transform: "scale(1)",
    },
    accordionRoot: {
      color: "#FFFFFF",
      margin: "0px",
      padding: "0px",
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      minHeight: "auto !important",
    },
    noSpaceNoMin: {
      margin: `${spaceMargin}`,
      padding: `${spaceMargin}`,
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
      /* marginBottom: '5px'   */
    },
    fieldRoot: {
      marginTop: "16px",
    },
    selectRoot: {
      padding: "24px",
    },
    datePickerRoot: {
      color: "#FFFFFF",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      "& .MuiIconButton-root, .MuiPickersDay-day, .MuiPickersCalendarHeader-dayLabel": {
        color: "#FFFFFF",
      },
      "& .MuiPickersDay-daySelected": {
        border: `${textFieldBorder}`,
      },
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: `${buttonBorder}`,
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    btnAddNUser: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: `${buttonBorder}`,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: `${buttonBorder}`,
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    listButton: {
      color: `${imprColor}`,
      background: "transparent !important",
      marginTop: "1rem !important",
      borderRadius: "0 !important",
      boxShadow: "0 0 black !important",
      padding: `${spaceMargin}`,
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    listButtonNew: {
      color: "#fff !important",
      background: `${imprColor}`,
      marginTop: "1rem !important",
      borderRadius: "0 !important",
      boxShadow: "0 0 black !important",
      textTransform: "none",
      whiteSpace: "nowrap",
    },
    appTitle: {
      width: "100%",
    },
    appContainer: {
      padding: "1rem",
      background: "#2b2b36",
      marginTop: "1rem",
    },
    buttonText: {
      color: "#0089FF",
      margin: "1em",
      textTransform: "none",
      fontSize: "14px",
    },
    dividerColor: {
      background: "#464659",
    },
    formContent: {
      marginBottom: "1rem",
    },
    stepperRoot: {
      width: "50%",
      margin: "0 auto",
    },
    stepactive: {
      color: "#FFF",
    },
    stepname: {
      color: "#FFF",
      "& span": {
        display: "flex",
        flexDirection: "column",
      },
    },
    alternativeLabel: {
      color: "yellow !important",
      "&$active": {
        color: `${stepsColor}`,
      },
    },
    labelContainer: {
      color: `${stepsColor}`,
      "&$alternativeLabel": {
        marginTop: 0,
        color: "orange !important",
      },
      "&$active": {
        color: `${stepsColor}`,
      },
      "&$completed": {
        color: "green !important",
      },
    },
    completed: {
      color: `${imprColor}`,
    },
    step: {
      "&$completed": {
        color: "#0089ff",
      },
      "&$active": {
        color: "cornflowerblue",
      },
      "&$disabled": {
        color: "red",
      },
    },
    active: {
      color: "cornflowerblue !important",
    },
    steplabel: {
      color: "#FFF",
      "& span": {
        color: "darkgray !important",
      },
      "& .active": {
        color: "green !important",
      },
    },
    hidden: {
      display: "none",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    activelevel: {
      color: "rgb(251, 206, 7)",
    },
    levelSearch: {
      height: "40px",
      backgroundColor: "#2B2B36",
      border: "transparent",
      color: "white",
      paddingLeft: "23px"
    },
    levelBlock: {
      display: "flex",
      flexDirection: "column",
      padding: "0px 20px",
      overflow: "hidden",
      boxShadow: "none",
      margin: "10px",
      backgroundColor: "rgb(29, 29, 37)",
      color: "white",
    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "&:hover": {
        color: "#0089FF !important",
      },
      "& svg": {
        marginRight: "8px",
      },
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
    },
    menuButtonRoot: {
      padding: "0px",
      float: "right",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
      paddingLeft: "0px",
      paddingRight: "0px",
      cursor: "pointer",
      "& span": {
        paddingLeft: "5px",
      },
    },
    head: {
      backgroundColor: "#1D1D25",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "12px",
      background: "#1D1D25",
    },
  })
)(TableCell);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

interface ParentComponentProps {
  clickCancel: any;
  editItem: any;
}

const EditApplication: React.FC<ParentComponentProps> = (props) => {
  const { user } = useStore();
  const classes = useStyles();
  const [openedit, setOpenEdit] = React.useState(false);
  const handleCancel = () => {
    props.clickCancel();
  };
  const { enqueueSnackbar } = useSnackbar();
  const [tags, setTags] = React.useState([] as any);
  const [appName, setAppName] = React.useState("");
  const [serReq, setServiceReq] = React.useState("");
  const [apexId, setApedId] = React.useState("");
  const [editLevels, setEditLevels] = React.useState([] as any);
  const [lineOf, setLineOfBuss] = React.useState("");
  const [appId, setAppId] = React.useState(0);
  const [currState, setCurrentState] = React.useState(null as any);
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const [appEditLevel, setAppEditLevel] = React.useState(false);
  const [editLevelFieldInput, setEditLevelFieldInput] = React.useState([{ index_id: "", level_id: -1, level_name: "", operation: "" }]);
  const [menu1, setMenu1] = React.useState([] as any);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [editfield, setEditFieldName] = React.useState("");
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);

  const [search, setSearch] = React.useState("");

  const {refreshApplications} = useApplicationContext();

  const handleChangeSearch = (event: any) => {
    const target = event.target.value;
    setSearch(target);
  };

  function getSteps() {
    return ["Application Details", "Application Levels"];
  }


  const handleChange = (index: number, event: any) => {
    const temp = [...tags];
    temp[index].value = event.target.value;
    setTags(temp);
  };
  const deleteProperty = (index: number) => {
    const temp = tags.filter((tag: any, ind: number) => ind !== index);
    setTags(temp);
  };

  const findStyleByOp = (operation: any) => {
    switch (operation) {
      case 'delete':
        return { fontSize: 'small', color: "#D10000", marginLeft: '0.5em', }
      case 'update':
        return { fontSize: 'small', color: "#FFE87C", marginLeft: '0.5em' }
      case 'add':
        return { fontSize: 'small', color: "#03C03C", marginLeft: '0.5em' }
      default:
        return { display: 'none' }
    }
  };


  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));

  const saveApplication = async () => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;

    const editedLevels = [] as any;
    editLevels.forEach((element: any) => {
      if (element.operation) {
        editedLevels.push({
          level_id: element.level_id,
          level_name: element.level_name,
          operation: element.operation
        })
      }
    });


    const APPS_API = `${API["GETALLAPPLS"]}/${appId}`;
    if (editLevels.length === 0) {
      enqueueSnackbar("Please add atleast one level.", { variant: "error" });
    } else {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
        body: JSON.stringify({
          pm_s_token: pmST,
          pm_s_id: pmSId,
          application: {
            application_name: appName,
            snow_req_id: serReq,
            apex_id: apexId,
            metadata: {
              lob: lineOf,
            },
            fields: tags,
            levels: editedLevels,
          },
        }),
      };

      await fetch(APPS_API, requestOptions)
        .then(function (response: any) {
          response.json().then((response: any) => {
          if (response.status === 200 || response.status === 201) {
            enqueueSnackbar("Application Changed.", { variant: "success" });
            refreshApplications();
            handleCancel();
          }
          else if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
            try {
              const tokenKey = "sso_token";
              callXhrRequest().then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                saveApplication();
              }).catch(function (error) {
                console.log('error', error)
                user.triggerLogout();
              })
            } catch (error) {
              console.log(error);
            }
            return;
          }
          else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
            try {
              user.triggerLogout();
            } catch (error) {
              console.log(error);
            }
            return;
          }
          else {
            const errorMessage = response?.message || DEFAULT_API_ERROR_MSG;
            enqueueSnackbar(errorMessage, { variant:'error' })
          }
        })
      })
        .catch(function (error) {
          console.log(error);
        });
      setTimeout(function () {
        props.clickCancel();
        setActiveStep(0);
      }, 500)
    }

  };
 
  const [addFieldInput, setAddFieldInput] = useState([{ name: "" }]);
  const [open, setOpen] = React.useState(false);
  const handleAddNewFields = () => {
    setAddFieldInput([...addFieldInput, { name: "" }]);
  };
  const [appLevel, setOpenAppLevel] = React.useState(false);
  const [addLevelFieldInput, setAddLevelFieldInput] = useState([
    { level_id: -1, level_name: "", operation: "add" },
  ]);
  const handleChangeAddNewLevels = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values = [...addLevelFieldInput];
    values[index].level_name = event.target.value;
    setAddLevelFieldInput(values);
  };
  const handleAddNewLevelFields = () => {
    setAddLevelFieldInput([
      ...addLevelFieldInput,
      { level_id: -1, level_name: "", operation: "add" },
    ]);
  };
  const onChangeAddNewFields = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values:any = [...addFieldInput];
    values[index][event.target.name] = event.target.value;
    setAddFieldInput(values);
  };
  const handleDeleteNewFields = (index: number) => {
    const values = [...addFieldInput];
    values.splice(index, 1);
    setAddFieldInput(values);
  };

  const handleDeleteNewLevel = (index: number) => {
    const values = [...addLevelFieldInput];
    values.splice(index, 1);
    setAddLevelFieldInput(values);
  };


  const regexCheck = /^[0-9a-zA-Z|]+$/;
  const handleSubmitNewFields = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const levelsValue = [...addLevelFieldInput];
    const editLevelsData: any = [...editLevels];
    levelsValue.forEach((e) => {
      if ((e.level_name).length === 0) {
        enqueueSnackbar("Please enter correct levelname.", { variant: "error" });
      } else if (!(regexCheck.test(e.level_name))) {
        enqueueSnackbar(INVALID_LEVEL_NAME_ERROR_MSG, { variant: "error" });
      } else if (editLevelsData.some(({level_name}:any)=>level_name === e.level_name)) {
        enqueueSnackbar("can not add duplicate application level name",{ variant: "error" });
        return;
      } else {
        editLevelsData.push(e)
        setOpen(false);
        setOpenAppLevel(false);
      }
    });
    setEditLevels(editLevelsData);
    const menuClose1 = menu1.map((m: any) => false);
    setMenu1(menuClose1);
    setAddLevelFieldInput([{ level_id: -1, level_name: "", operation: "add" }])
  };


  const addNewFieldHandler = (e: any) => {
    const temp = [...tags, ...addFieldInput];
    let flag = false
    const valueArr = temp.map((item: any) => {
      return item.name
    });
    const isDuplicate = valueArr.some((item, idx) => {
      return valueArr.indexOf(item) !== idx
    });

    temp.forEach((e: any) => {
      if (e.name === '' && e.name.length === 0) {
        enqueueSnackbar("Field name can't be empty.", { variant: "error" });
        flag = false
      } else if (e.name.length > 50) {
        enqueueSnackbar("Field name only accept upto 50 character.", { variant: "error" });
        flag = false
      } else if (!(regexCheck.test(e.name))) {
        enqueueSnackbar("Field name accept only character.", { variant: "error" });
        flag = false
      }
      else if (isDuplicate) {
        enqueueSnackbar("Field name can't be duplicate.", { variant: "error" });
        flag = false
      } else {
        flag = true
      }
    })
    if (flag) {
      setTags(temp);
      setOpen(false);
    }
    setAddFieldInput([{ name: "" }])
  }


  const editFieldAction = (index: number) => {
    const temp = tags.filter((tag: any, ind: number) => ind === index);
    setEditFieldName(temp[0].name);
    setOpenEdit(true);
  };

  const dialogHandleCloseDelete = () => {
    setOpenDialogDelete(false);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = (event: any) => {
    event.preventDefault();
    const regexCheck = /^[0-9a-zA-Z_-]+$/;
    if (!appName) {
      enqueueSnackbar("Please enter application name.", { variant: "error" });
    } else if (!(regexCheck.test(appName) && appName.length < 50)) {
      enqueueSnackbar('Invalid application name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)', { variant: "error" });
    } else if (!serReq) {
      enqueueSnackbar("Please enter service now id.", { variant: "error" });
    } else if (!(regexCheck.test(serReq) && serReq.length < 50)) {
      enqueueSnackbar('Invalid  service now id (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)', { variant: "error" });
    } else if (!apexId) {
      enqueueSnackbar("Please enter apex id.", { variant: "error" });
    } else if (!(regexCheck.test(apexId) && apexId.length < 50)) {
      enqueueSnackbar('Invalid apex id (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)', { variant: "error" });
    } else if (!lineOf) {
      enqueueSnackbar("Please enter line of business.", { variant: "error" });
    } else if (!(regexCheck.test(lineOf) && lineOf.length < 50)) {
      enqueueSnackbar('Invalid line of business. (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)', { variant: "error" });
    } else {
      const newSkipped = skipped;
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const addAnotherLevel = () => {
    setOpenAppLevel(true);
  };

  const OnValueMenuOpen = (item: any) => async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setCurrentState(item);
  };

  const OnMenuOpen = (item: any) => async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setCurrentState(item);
    setAnchorEl1(event.currentTarget);
    const levelMenu = [...menu1];
    levelMenu[item] = true;
    setMenu1(levelMenu);
  };

  const handleClose = () => {
    const menuClose1 = menu1.map((m: any) => false);
    setMenu1(menuClose1);
    setAnchorEl1(null);
  };

  const deleteSubLevel = (index: any) => {
    const newLevels = [...editLevels];
    newLevels[index].operation = 'delete'
    setEditLevels(newLevels);
    const menuClose1 = menu1.map((m: any) => false);
    setMenu1(menuClose1);
  };
  const handleChangeEditLevels = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const values = [...editLevelFieldInput];
    values[index].level_name = event.target.value;
    setEditLevelFieldInput(values);
  };

  const editAppLevel = (index: number, level: string) => {
    const newLevels = [...editLevels];
    const values = [] as any;
    if (newLevels[index].operation === "add") {
      values.push({ index_id: index, level_id: newLevels[index].level_id, level_name: newLevels[index].level_name, operation: "add" })
    }
    else {
      values.push({ index_id: index, level_id: newLevels[index].level_id, level_name: newLevels[index].level_name, operation: "update" })
    }
    setEditLevelFieldInput(values);
    setAppEditLevel(true);
    const menuClose1 = menu1.map((m: any) => false);
    setMenu1(menuClose1);
  };
  const onSubmitEditLevel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const newLevels: any = [...editLevels];
    const applicationLevel: string = editLevelFieldInput[0].level_name;
    const ind: any = editLevelFieldInput[0].index_id;
    
    if (applicationLevel.length === 0) {
      enqueueSnackbar("Please enter correct levelname.", { variant: "error" });
      } else if (!(regexCheck.test(applicationLevel))) {
        enqueueSnackbar(INVALID_LEVEL_NAME_ERROR_MSG, { variant: "error" });
      } else if (newLevels.some(({level_name}:any)=>level_name === applicationLevel)) {
        enqueueSnackbar("Application level already exists",{ variant: "error" });
        return;
      } else {
      newLevels[ind] = editLevelFieldInput[0]
      setEditLevels(newLevels);
      setAppEditLevel(false);
    }

    setAnchorEl1(null);
    setAddLevelFieldInput([{ level_id: -1, level_name: "", operation: "add" }]);
  };

  React.useEffect(() => {
    setAppName(props.editItem.application_name);
    setServiceReq(props.editItem.snow_req_id);
    setApedId(props.editItem.apex_id);
    setLineOfBuss(props.editItem.metadata && props.editItem.metadata.lob);
    setEditLevels(props.editItem.levels);
    setAppId(props.editItem.application_id);
  }, [props.editItem]);
  return (
    <div className={classes.root}>
      <div className={classes.stepperRoot}>
        <Stepper activeStep={activeStep} className={classes.stepactive}>
          {steps.map((label, index) => {
            return (
              <Step key={label} className={classes.stepname}>
                <StepLabel
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    labelContainer: classes.labelContainer,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.step,
                      completed: classes.completed,
                      active: classes.active,
                    },
                  }}
                  className={classes.steplabel}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>

      <form className={classes.form} noValidate method="post">
        <Grid container className={activeStep !== 0 ? classes.hidden : ""}>
          <Grid item xs={6} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="appname"
              label="Application Name"
              type="text"
              id="appname"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setAppName(e.target.value),
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              value={appName}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceLeft}>
            <CssTextField
              fullWidth
              name="serviceid"
              label="Service Now ID"
              type="text"
              id="serviceid"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setServiceReq(e.target.value),
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              value={serReq}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceRight}>
            <CssTextField
              fullWidth
              name="apexid"
              label="Apex ID"
              type="text"
              id="apexid"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setApedId(e.target.value),
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              value={apexId}
            />
          </Grid>
          <Grid item xs={6} className={classes.spaceLeft}>
            <CssTextField
              fullWidth
              name="lineofbusiness"
              label="Line of Business"
              type="text"
              id="lineofbusiness"
              className={classes.fieldWrapper}
              InputProps={{
                disableUnderline: true,
                onChange: (e) => setLineOfBuss(e.target.value),
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.labels,
              }}
              value={lineOf}
            />
          </Grid>
          {tags.map((tag: any, index: any) => (
            <Grid
              item
              xs={6}
              className={
                index % 2 === 1 ? classes.spaceLeft : classes.spaceRight
              }
            >
              <InputLabel
                style={{ marginTop: "1em" }}
                classes={{ root: classes.labels }}
                htmlFor={tag.name}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {tag.name}
                  <div>
                    <DeleteIcon
                      style={{ marginLeft: "1em" }}
                      fontSize="small"
                      onClick={() => {
                        deleteProperty(index);
                      }}
                    ></DeleteIcon>
                    <EditIcon
                      style={{ marginLeft: "1em" }}
                      fontSize="small"
                      onClick={() => editFieldAction(index)}
                    ></EditIcon>
                  </div>
                </Grid>
              </InputLabel>
              <CssTextField
                fullWidth
                name={tag.name}
                type={tag.name}
                id={tag.name}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                onChange={(event) => handleChange(index, event)}
                value={tag.value}
              />
            </Grid>
          ))}

          <Grid item xs={6} sm={2}>
            <div className={classes.rightSec}>
              <Button
                variant="contained"
                color="inherit"
                className={classes.listButton}
                endIcon={<AddIcon />}
                size="large"
                onClick={() => setOpen(true)}
              >
                Add New Field
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          className={activeStep !== 1 ? classes.hidden : classes.appContainer}
        >
          <div className={classes.appTitle}>
            <Typography className={classes.colorWhite}>
              Application Levels
            </Typography>
          </div>
          <div style={{marginLeft:10}}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="inherit"
                className={classes.listButtonNew}
                endIcon={<AddIcon />}
                size="large"
                onClick={() => addAnotherLevel()}
              >
                Create Application Level
              </Button>
            </Grid>
          </div>
          <Grid item xs={12} sm={12} container>
            <div className={classes.levelBlock}>
              <StyledTableCell>
                <span> Level </span>
              </StyledTableCell>
              <div>
                <input type="search" className={classes.levelSearch} placeholder="Search..." onChange={(event) => handleChangeSearch(event)} />
              </div>

              {Array.isArray(editLevels) &&
                editLevels.filter((item: any) => item.level_name.toLowerCase().includes(search.toLowerCase()))
                .map((item: any, indx: number) => (
                  <StyledTableCell>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Grid container direction="row" alignItems="center">
                          <span
                            className={indx === currState ? classes.activelevel : ""}
                            onClick={OnValueMenuOpen(indx)}
                            style={item.operation === 'delete' ? { textDecoration: 'line-through' } : {}}
                          >
                            {item.level_name}
                          </span>
                          <FiberManualRecordIcon style={findStyleByOp(item.operation)}></FiberManualRecordIcon>
                        </Grid>
                      </Grid>


                      <IconButton
                        onClick={OnMenuOpen(indx)}
                        classes={{ root: classes.menuButtonRoot }}
                      >
                        <MoreVertIcon color="secondary" />
                      </IconButton>
                    </Grid>

                    <Menu
                      id="user-menu"
                      anchorEl={anchorEl1}
                      keepMounted
                      open={menu1[indx]}
                      onClose={handleClose}
                      classes={{ paper: classes.menuRoot }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem
                        className={classes.menuitem}
                        onClick={() => editAppLevel(indx, "level")}
                      >
                        <EditIcon fontSize="small"></EditIcon>
                        Edit
                      </MenuItem>
                      <MenuItem
                        className={classes.menuitem}
                        onClick={() => deleteSubLevel(indx)}
                      >
                        <DeleteIcon fontSize="small"></DeleteIcon>
                        Delete
                      </MenuItem>
                    </Menu>
                  </StyledTableCell>
                ))}
            </div>
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          classes={{ paper: classes.dialogRoot }}
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setOpen(false)}
          >
            Add New Field
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <form
              className={classes.formContent}
              onSubmit={handleSubmitNewFields}
            >
              {addFieldInput.map((inputField, index) => (
                <div>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Grid item xs={10} className={classes.spaceLeft}>
                      <CssTextField
                        fullWidth
                        name="name"
                        label="Field Name *"
                        type="name"
                        id="name"
                        className={classes.fieldWrapper}
                        InputProps={{
                          disableUnderline: true,
                          onChange: (event) =>
                            onChangeAddNewFields(index, event),
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labels,
                        }}
                        value={inputField.name}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <Button onClick={() => handleDeleteNewFields(index)}>
                        <DeleteIcon style={{ color: "#FFFFFF" }}></DeleteIcon>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Button
                variant="contained"
                color="inherit"
                className={classes.listButton}
                endIcon={<AddIcon />}
                size="large"
                onClick={() => handleAddNewFields()}
              >
                Add New Field
              </Button>
            </form>
          </DialogContent>
          <ThemeProvider theme={theme1}>
            <Divider
              variant="middle"
              classes={{ middle: classes.dividerColor }}
            />
          </ThemeProvider>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={addNewFieldHandler}
              variant="contained"
              className="dcBtEdit"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          classes={{ paper: classes.dialogRoot }}
          onClose={() => setOpenEdit(false)}
          aria-labelledby="customized-dialog-title"
          open={openedit}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setOpenEdit(false)}
          >
            Edit Field
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <form className={classes.formContent}>
              <div>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <Grid item xs={10} className={classes.spaceLeft}>
                    <CssTextField
                      fullWidth
                      name="fieldName"
                      label="Field Name"
                      type="fieldName"
                      id="fieldName"
                      className={classes.fieldWrapper}
                      InputProps={{
                        disableUnderline: true,
                        onChange: (event) =>
                          setEditFieldName(event.target.value),
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labels,
                      }}
                      value={editfield}
                    />
                  </Grid>
                </Grid>
              </div>
            </form>
          </DialogContent>
          <ThemeProvider theme={theme1}>
            <Divider
              variant="middle"
              classes={{ middle: classes.dividerColor }}
            />
          </ThemeProvider>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenEdit(false);
                setEditFieldName("");
              }}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => console.log(e)}
              variant="contained"
              className="dcBtEdit"
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{ paper: classes.dialogRootSmall }}
          onClose={dialogHandleCloseDelete}
          aria-labelledby="customized-dialog-title"
          open={openDialogDelete}
        >
          <Grid container direction="row" alignItems="center">
            <img
              style={{ marginLeft: "1em" }}
              alt="Delete Logo"
              src={deleteLogo}
            ></img>
            <DialogTitle
              id="customized-dialog-title"
              onClose={dialogHandleCloseDelete}
            >
              Delete Field?
            </DialogTitle>
          </Grid>
          <DialogContent>
            <Typography
              style={{
                fontFamily: "Avenir Next",
                fontSize: "14px",
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                margin: "1em",
                color: "#FFFFFF",
              }}
            >
              Are you sure you want to delete this Field? This Field cannot be
              recovered.
            </Typography>
          </DialogContent>
          <Divider
            variant="middle"
            classes={{ middle: classes.dividerColor }}
          />

          <DialogActions>
            <Button
              onClick={dialogHandleCloseDelete}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => console.log(e)}
              variant="contained"
              className="dcBtEdit"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          classes={{ paper: classes.dialogRoot }}
          onClose={() => setOpenAppLevel(false)}
          aria-labelledby="customized-dialog-title"
          open={appLevel}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setOpenAppLevel(false)}
          >
            Add Level Details
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <form className={classes.formContent}>
              {addLevelFieldInput.map((inputField, index) => (
                <div>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Grid item xs={10} className={classes.spaceLeft}>
                      <CssTextField
                        fullWidth
                        name="levelName"
                        label="Level Name"
                        type="levelName"
                        id="levelName"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labels,
                        }}
                        onChange={(event) =>
                          handleChangeAddNewLevels(index, event)
                        }
                        value={inputField.level_name}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button className={classes.fieldWrapper} onClick={() => handleDeleteNewLevel(index)}>
                        <DeleteIcon style={{ color: "#FFFFFF" }}></DeleteIcon>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Button
                variant="contained"
                color="inherit"
                className={classes.listButton}
                endIcon={<AddIcon />}
                size="large"
                onClick={() => handleAddNewLevelFields()}
              >
                Add New Level
              </Button>
            </form>
          </DialogContent>
          <ThemeProvider theme={theme1}>
            <Divider
              variant="middle"
              classes={{ middle: classes.dividerColor }}
            />
          </ThemeProvider>
          <DialogActions>
            <Button
              onClick={() => setOpenAppLevel(false)}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitNewFields}
              variant="contained"
              className="dcBtEdit"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          classes={{ paper: classes.dialogRoot }}
          onClose={() => setAppEditLevel(false)}
          aria-labelledby="customized-dialog-title"
          open={appEditLevel}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => setAppEditLevel(false)}
          >
            Edit Level Details
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <form className={classes.formContent}>
              {editLevelFieldInput.map((row: any, index: number) => (
                <div>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Grid item xs={10} className={classes.spaceLeft}>
                      <CssTextField
                        fullWidth
                        name="levelName"
                        label="Level Name"
                        type="levelName"
                        id="levelName"
                        className={classes.fieldWrapper}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labels,
                        }}
                        onChange={(event) =>
                          handleChangeEditLevels(index, event)
                        }
                        value={row.level_name}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))}
            </form>
          </DialogContent>

          <ThemeProvider theme={theme1}>
            <Divider
              variant="middle"
              classes={{ middle: classes.dividerColor }}
            />
          </ThemeProvider>
          <DialogActions>
            <Button
              onClick={() => setAppEditLevel(false)}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmitEditLevel}
              variant="contained"
              className="dcBtEdit"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={activeStep === 0 ? handleCancel : handleBack}
          >
            {activeStep === 0 ? "Cancel" : "Back"}
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNUser}
            onClick={
              activeStep === steps.length - 1 ? saveApplication : handleNext
            }
          >
            {activeStep === steps.length - 1 ? "Save Application" : "Next"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditApplication;
