import React from "react";
import { FormSection, GriddedTextField } from "../../../UiComponents";

const Properties: React.FC<any> = ({ properties, onPropertyChange }) => {
  return (
    <FormSection header="Properties">
      {Object.keys(properties).map((property: any) => (
        <GriddedTextField
          label={property}
          key={property}
          value={properties[property]}
          onChange={(value: string) => onPropertyChange(property, value)}
        />
      ))}
    </FormSection>
  );
};

export default Properties;
