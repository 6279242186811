import { Button } from "@material-ui/core";
import React, { useState } from "react";

import { useSnackbar } from "notistack";
import axios from "axios";

import exportLogo from "../../../assets/Export.png";
import {
  arrayOfErrorsRefresh,
  arrayOfErrorsLogout,
  exportToExcel,
} from "../../../utils/helper";

import callXhrRequest from "../../../utils/xhrRequestHandler";
import { useStore } from "mobx-store-provider";
import { API_URL } from "../../../api/property";
import { useApplicationContext } from "../../../contexts/ApplicationContext";
import ExportColumnSelectionDialog from "./ExportColumnSelectionDialog";
import useStyles from "./ExportEventsToCSV.styles";

const MAXIMUM_EXPORTABLE_RECORD_COUNT = 20000;
/* TODO: Implement re-usable component for export once all pages are aligned with common filter mechanism*/
/* TODO:We can use useApi hook here to keep things simple and reusable. Will work on it later*/
const ExportToCSV: React.FC<any> = ({
  columns,
  filter,
  sortingInfo,
  expectedRecordCount,
}) => {
  const { selectedApplication } = useApplicationContext();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showExportColumnSelectionDialog, setShowExportColumnSelectionDialog] =
    useState(false);
  const { user } = useStore();

  const exportData = async (selectedColumns: any[]) => {
    const accessToken = JSON.parse(
      sessionStorage.getItem("sso_token") as string
    );
    const DEVICES_API = `${API_URL}/api/v1d/applications/${selectedApplication.application_id}/dlm-msgs-export`;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken.access_token} `,
        "Ocp-Apim-Subscription-Key": window.SERVER_DATA.REACT_APP_PM_API_KEY,
        "Ocp-Apim-Trace": `true`,
      },
      params: {
        pm_s_token: sessionStorage.getItem("pm_s_token"),
        pm_s_id: sessionStorage.getItem("pm_s_id"),
        filter,
        ...sortingInfo,
      },
    };

    try {
      const { data: response }: any = await axios.get(
        DEVICES_API,
        requestOptions
      );

      const csvData = (response.dlm_msgs || []).map((dlmMessageData: any) => {
        const csvRow = selectedColumns?.reduce(
          (acc, { id, label, resolver }: any) => {
            return {
              ...acc,
              [label]: resolver ? resolver(dlmMessageData) : dlmMessageData[id],
            };
          },
          {}
        );
        return csvRow;
      });
      const {application_name} = selectedApplication;
      exportToExcel(csvData, "Events", 9, application_name); //TODO: Why 9?
    } catch (ex) {
      const errorResponse = ex.response && ex.response.data;
      if (
        errorResponse &&
        errorResponse.status === 401 &&
        ex &&
        arrayOfErrorsRefresh.includes(errorResponse.message.toLowerCase())
      ) {
        try {
          const tokenKey = "sso_token";

          callXhrRequest()
            .then(function (data) {
              sessionStorage.setItem(tokenKey, data);

              exportData(selectedColumns);
            })
            .catch(function (error) {
              user.triggerLogout();
              console.log("error", error);
            });
        } catch (error) {
          console.log(error);
        }
        return;
      } else if (
        errorResponse &&
        errorResponse.status === 401 &&
        errorResponse &&
        arrayOfErrorsLogout.includes(errorResponse.message.toLowerCase())
      ) {
        try {
          user.triggerLogout();
        } catch (error) {
          console.log(error);
        }
        return;
      } else {
        enqueueSnackbar("Could not fetch events.", {
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Button
        variant="text"
        color="inherit"
        className={classes.listButton}
        startIcon={<img alt="Export Logo" src={exportLogo}></img>}
        onClick={() => {
          if (expectedRecordCount > MAXIMUM_EXPORTABLE_RECORD_COUNT) {
            enqueueSnackbar(
              `Export limit is set to ${MAXIMUM_EXPORTABLE_RECORD_COUNT} records. Please narrow your search window to reduce record count.`,
              { variant: "info" }
            );
            return;
          }
          setShowExportColumnSelectionDialog(true);
        }}
      >
        Export
      </Button>
      {showExportColumnSelectionDialog && (
        <ExportColumnSelectionDialog
          columns={columns}
          onClose={() => setShowExportColumnSelectionDialog(false)}
          onSelect={(selectedColumns: any[]) => {
            exportData(selectedColumns);
            setShowExportColumnSelectionDialog(false);
            enqueueSnackbar("Export Initiated", { variant: "success" });
          }}
        />
      )}
    </>
  );
};

export default ExportToCSV;
