import { ThemeProvider } from "@material-ui/styles";
import theme from "./components/Theme";
import React, {
  useState,
  useEffect,
  createContext,
  useReducer,
  useContext,
} from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Users from "./components/Users/Users";
import Roles from "./components/Roles/Roles";
import Applications from "./components/Application/Applications";
import Dashboard from "./components/Dashboard/DashboardAlpha";
import Devices from "./components/Devices/DeviceManagement";
import Resources from "./components/Resources/Resources";
import DeviceConfig from "./components/Configurations/ShowConfigurations";
import ConnConfig from "./components/Configurations/ShowConnectionConfigurations";
import Events from "./components/Events";
import ValidateRoute from "./components/ValidateRoute";
import Header from "./components/Header/Header";
import DeviceInstallation from "./components/Devices/DeviceInstallation";
import { reducer, initialState } from "./reducers/userReducer";
import { CssBaseline } from "@material-ui/core";
import Login from "./components/Login";
import { IAppStore } from "./user/AppStore";
import { useStore } from "mobx-store-provider";
import axios from "axios";
import { API } from "./api/property";
import JWTDecode from "jwt-decode";
import { ApplicationContextProvider } from "./contexts/ApplicationContext";

export const UserContext = createContext<Partial<ContextProps>>({});

const ApplicationContextAdapter: React.FC<any> = ({ children }) => {
  const history = useHistory();

  const { dispatch } = useContext(UserContext);
  const { user }: IAppStore = useStore();
  const [sessionReady, setSessionReady] = useState(false);

  useEffect(() => {
    if (!user.isLoggedIn && !user.isLoggingIn) {
      window.sessionStorage.setItem(loggedin, "false");
      user.handleLoginRedirect();
    } else {
      if (sessionStorage.getItem(loggedin) === "false") {
        window.sessionStorage.setItem(loggedin, "true");
        const tokenKey = "sso_token";
        const accessToken = JSON.parse(
          sessionStorage.getItem(tokenKey) as string
        );
        const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
        const PMSId = sessionStorage.getItem("pm_s_id");
        const PMStoken = sessionStorage.getItem("pm_s_token");
        if (!PMSId && !PMStoken) {
          const jwList = JWTDecode(accessToken.access_token) as any;
          var data = JSON.stringify({ email_id: jwList.mail.toLowerCase() });

          axios({
            method: "post",
            url: API["POSTLOGIN"],
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken.access_token}`,
              "Ocp-Apim-Subscription-Key": `${API_KEY}`,
              "Ocp-Apim-Trace": `true`,
            },
            data: data,
          })
            .then(function (response: any) {
              const { pm_s_id, pm_s_token } = response.data;
              sessionStorage.setItem("pm_s_id", pm_s_id);
              sessionStorage.setItem("pm_s_token", pm_s_token);
              setSessionReady(true);
            })
            .catch(function (error) {
              console.log(error);
              user.triggerLogout();
              window.sessionStorage.setItem(authentication, "false");
            });
        } else {
          setSessionReady(true);
        }
        history.push("/dashboard");
      } else {
        setSessionReady(true);
      }
    }
  }, [dispatch, history, user, sessionReady]);

  if (user.isLoggedIn) {
    if (sessionReady) {
      return (
        <ApplicationContextProvider>{children}</ApplicationContextProvider>
      );
    }
    return <div>Session initializing</div>;
  } else {
    return <Login />;
  }
};

type ContextProps = {
  state: any;
  dispatch: any;
};
const authentication = "authentication";

const drawerWidth = 64;
const loggedin = "logged_in";

const Routing = () => {
  const { user }: IAppStore = useStore();
  return (
    <>
      {user.isLoggedIn && <HandleHeader />}
      <ApplicationContextAdapter>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/roles">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <Roles />
              </div>
            </ValidateRoute>
          </Route>
          <Route exact path="/applications">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <Applications />
              </div>
            </ValidateRoute>
          </Route>
          <Route exact path="/dashboard">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <Dashboard />
              </div>
            </ValidateRoute>
          </Route>
          <Route exact path="/devices">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <Devices />
              </div>
            </ValidateRoute>
          </Route>

          <Route exact path="/resources">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <Resources />
              </div>
            </ValidateRoute>
          </Route>
          <Route path="/device-config">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <DeviceConfig />
              </div>
            </ValidateRoute>
          </Route>
          <Route exact path="/conn-config">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <ConnConfig />
              </div>
            </ValidateRoute>
          </Route>
          <Route exact path="/events">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <Events />
              </div>
            </ValidateRoute>
          </Route>
          <Route exact path="/users">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <Users />
              </div>
            </ValidateRoute>
          </Route>
          <Route exact path="/device-management">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <Devices />
              </div>
            </ValidateRoute>
          </Route>
          <Route exact path="/device-installation">
            <ValidateRoute>
              <div
                style={{ marginLeft: `${drawerWidth}px`, overflowX: "hidden" }}
              >
                <DeviceInstallation />
              </div>
            </ValidateRoute>
          </Route>
        </Switch>
      </ApplicationContextAdapter>
    </>
  );
};

const HandleHeader = () => {
  return (
    <>
      <React.Fragment>
        <ValidateRoute>
          <Header />
        </ValidateRoute>
      </React.Fragment>
    </>
  );
};
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <UserContext.Provider value={{ state, dispatch }}>
          <BrowserRouter>
            <div>
              <ApplicationContextAdapter>
                <Routing />
              </ApplicationContextAdapter>
            </div>
          </BrowserRouter>
        </UserContext.Provider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
