import React, { useContext } from "react";
import useApi from "../hooks/useApi";
import LoadingIndicator from "../components/UiComponents/LoadingIndicator";

const InputContext = React.createContext<any>({});

interface IInputContextProviderProps {
  children: React.ReactNode;
  applicationId: number;
  lookup?: any;
}

//When refactoring is complete, InputContextProvider can internally use useApplicationContext to get selected application
const InputContextProvider: React.FC<IInputContextProviderProps> = ({
  children,
  applicationId,
  lookup = {},
}) => {
  const { data: inputsData, status } = useApi("/devices/inputs", {
    method: "GET",
  });

  if (status.pending || !inputsData) {
    return <LoadingIndicator />;
  }

  const applicationInputs = inputsData.devices_input[0].applications.find(
    ({ application_id }: any) => application_id === applicationId
  );

  return (
    <InputContext.Provider value={{ ...applicationInputs, lookup }}>
      {children}
    </InputContext.Provider>
  );
};

const useInputContext = () => useContext<any>(InputContext);

export { InputContextProvider, useInputContext };
