import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {  Drawer, MenuItem, Menu, useScrollTrigger } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import theme from '../Theme';
import logo from '../../assets/logo.svg';
import { useHistory } from "react-router-dom";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logout from '../../assets/logout.png';
import deleteLogo from '../../assets/Delete.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DashboardIcon from '../../assets/LeftPanelIcons/Dashboard.png';
import DevicesIcon from '../../assets/LeftPanelIcons/Devices.png';
import ResourcesIcon from '../../assets/LeftPanelIcons/Resources.png';
import EventIcon from '../../assets/LeftPanelIcons/Events.png';
import PortalIcon from '../../assets/LeftPanelIcons/Portal.png';
import { IAppStore } from "../../user/AppStore";
import { useStore } from 'mobx-store-provider';
import DeviceMenu from './DeviceMenu';
import NotificationManager from '../UiComponents/NotificationManager';


const drawerWidth = 325;
const drawerWidthClosed = 64;
const font = 'Avenir Next';
const conn_config_page = 'conn-config';
const device_config_page = 'device-config';
const border_bottom_style = '2px solid #FBCE07';

const useStyles = makeStyles(theme => ({

    toolbarMargin: {
        ...theme.mixins.toolbar
    },


    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },


    logo: {
        padding: '10px 10px 10px 10px'
    },
    tabContainer: {
        marginLeft: 'auto'
    },
    tab: {
        minWidth: 10
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    menulink: {
        textDecoration: "none",
        color: "#FFF",
        marginRight: "20px"
    },
    toolbarSecondary: {
        overflowX: 'auto',
        backgroundColor: '#373746',
        padding: '0 30px'
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
        textDecoration: "none",
        color: '#FCFCFC',
        fontSize: '18px',
        marginRight: '25px'
    },
    hoveredStyle: {
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: "#2B2B36"
        }
    },
    headerStyle: {

        fontStyle: 'normal',
        fontFamily: font,
        fontWeight: 'bold',
        fontSize: '20px',
        display: 'flex',
        lineHeight: '27px',
        color: '#FFFFFF'


    },
    dividerColor: {
        background: '#FCFCFC',
        opacity: '50%'

    },
    dividerColor1: {
        background: '#1D1D25',
    },
    drawer: {
        color: '#FCFCFC',
        overflow: 'hidden',
        background: '#2B2B36',
        boxShadow: '0px 20px 100px #000000',
        borderRadius: '4px',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        overflow: 'hidden',
        color: '#FCFCFC',
        background: '#2B2B36',/* #2B2B36 */
        boxShadow: '0px 20px 100px #000000',
        borderRadius: '4px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        width: drawerWidthClosed,
        overflow: 'hidden',
        color: '#FCFCFC',
        background: '#2B2B36',
        boxShadow: '0px',
        borderRadius: '4px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: '4em',
    },
    leftpanel: {
        marginLeft: '-1em',
        width: '3em',
        height: '2.7em'
    },
    menuitem: {
        color: "#373746"
    }
}));


interface Props {
    children: React.ReactElement;
}

function ElevationScroll(props: Props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,

    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

export default function ButtonAppBar() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [openHandle, setOpenHandleLogout] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleToggleButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const iconClose = () => {
        setAnchorEl(null);
    }

    const userLogoutPopup = () => {
        setOpenHandleLogout(!openHandle);
    };
    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const userHandleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenHandleLogout(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const y: any[] = [];
    const [notifications, setNotifications] = React.useState(y);
    const history = useHistory();


    const [openDrawer, setOpenDrawer] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        setOpenUsers(false);
        setOpenDevices(false);
    };

    const [openUsers, setOpenUsers] = React.useState(false);

    const handleClickUsers = () => {
        setOpenUsers(!openUsers);
        setOpenDevices(false);
    };

    const [openDevices, setOpenDevices] = React.useState(false);

    const handleClickDevices = () => {
        setOpenDevices(!openDevices);
        setOpenUsers(false);

    };

    const [active, setActive] = React.useState('');
    useEffect(() => {
        if (window.location.pathname === '/dashboard') {
            setActive('dashboard');
        } else if (window.location.pathname === '/devices') {
            setActive('devices');
        } else if (window.location.pathname === '/conn-config') {
            setActive(conn_config_page);
        } else if (window.location.pathname === '/resources') {
            setActive('resources');
        } else if (window.location.pathname === '/events') {
            setActive('events');
        } else if (window.location.pathname === '/job') {
            setActive('jobs');
        } else if (window.location.pathname === '/health') {
            setActive('health');
        } else if (window.location.pathname === '/users') {
            setActive('users');
        } else if (window.location.pathname === '/roles') {
            setActive('roles');
        } else if (window.location.pathname === '/applications') {
            setActive('applications');
        } else if (window.location.pathname === '/help') {
            setActive('help');
        }
    }, [window.location.pathname, active])

    const { user }: IAppStore = useStore();

    return (
        <React.Fragment>
            <ElevationScroll>
                <AppBar position="fixed" color="primary" className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                    <Toolbar style={{ color: '#D1D1D6' }} /* variant='dense' */>
                        <Grid container direction='row' justify='space-between' alignItems='center'>
                            <Grid item>
                                <Grid container direction='row' alignItems='center'>
                                    <Grid item>
                                        <img className={classes.logo} alt="Company Logo" src={logo}></img>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.headerStyle}>
                                            IoT@Shell DLM
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>

                                <NotificationManager />


                                <IconButton
                                    aria-label="account of current user" className={classes.hoveredStyle}
                                    aria-haspopup="true"
                                    onClick={(event) => handleToggleButton(event)}
                                    color="inherit"
                                >
                                    <PersonOutlineIcon />
                                </IconButton>

                                <Menu id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={iconClose}
                                    className={classes.menuitem}
                                    PaperProps={{
                                        style: {
                                            backgroundColor: "#373746",
                                            fontFamily: font,
                                            fontSize: '14px',
                                            width: '15rem',

                                        }
                                    }}
                                    style={{ top: '40px', marginLeft: '-2rem' }}
                                >

                                    <MenuItem >{user.email}</MenuItem>
                                    <MenuItem onClick={(event) => {
                                        userHandleClose(event);
                                        userLogoutPopup();
                                    }}>
                                        <img style={{ marginRight: '0.4em' }} alt="logout Logo"
                                            src={logout}></img>
                                        Logout</MenuItem>

                                    <Dialog onClose={userHandleClose} open={openHandle}
                                        style={{ marginBottom: "170px" }}>
                                        <Grid container alignItems='center'>
                                            <img style={{ marginLeft: '1em' }} alt="Delete Logo"
                                                src={deleteLogo}></img>
                                            <DialogTitle onClick={userHandleClose}
                                                style={{ color: '#FFFFFF' }}>
                                                Logout?
                                            </DialogTitle>
                                        </Grid>
                                        <DialogContent>
                                            <Typography style={{
                                                fontFamily: font,
                                                fontSize: '14px',
                                                alignItems: 'left',
                                                margin: '2em',
                                                color: '#FFFFFF',
                                                width: '300px',
                                                height: "45px",
                                                marginTop: "15px",
                                                marginBottom: "1px"
                                            }}>
                                                Are you sure you want to logout?
                                            </Typography>

                                        </DialogContent>
                                        <Divider variant="middle" style={{
                                            background: '#464659',
                                            margin: '0em',
                                            marginBottom: "0.5em"
                                        }} />

                                        <DialogActions>
                                            <Button onClick={userHandleClose} variant="outlined"
                                                style={{
                                                    color: "blue",
                                                    borderColor: "#464659",
                                                    textTransform: 'none',
                                                    fontWeight: 'bold',
                                                    right: "215px",
                                                    borderRadius: "0px",
                                                    bottom: '3px'
                                                }}>
                                                Cancel
                                            </Button>

                                            <Button onClick={user.triggerLogout}
                                                variant="contained" style={{
                                                    background: "#1976d2",
                                                    color: "white",
                                                    textTransform: 'none',
                                                    right: "10px",
                                                    borderRadius: "0px",
                                                    bottom: '3px'
                                                }}>
                                                Logout
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

            </ElevationScroll>
            <Drawer
                onMouseEnter={handleDrawerOpen}
                onMouseLeave={handleDrawerClose}
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: openDrawer,
                    [classes.drawerClose]: !openDrawer,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: openDrawer,
                        [classes.drawerClose]: !openDrawer,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem onClick={() => {
                        active === 'dashboard' ? window.location.reload() : history.push("/dashboard");
                        // history.push("/dashboard");
                        setActive('dashboard');
                        handleDrawerClose();
                    }} style={{
                        marginTop: '0.1em',
                        marginBottom: '0.5em',
                        marginLeft: '0.5em',
                        background: (active === 'dashboard' ? '#1D1D25' : '#2B2B36'),
                        borderBottom: (active === 'dashboard' ? border_bottom_style : '')
                    }} button key='Dashboard'>
                        <ListItemIcon><img className={classes.leftpanel} alt="logout Logo"
                            src={DashboardIcon} /></ListItemIcon>
                        <ListItemText primary={"Dashboard"} />
                    </ListItem>                    
                    <DeviceMenu  active={active} drawerOpen={openDrawer} onClick={() => {
                        setActive('devices');
                        handleDrawerClose();
                    }}/>
                    <ListItem onClick={() => {
                        handleClickDevices();
                    }} style={{
                        marginTop: '0.1em',
                        marginLeft: '0.5em',
                        background: (active === conn_config_page || active === device_config_page ? '#1D1D25' : '#2B2B36'),
                        borderBottom: (active === device_config_page || active === conn_config_page ? border_bottom_style : '')
                    }} button key='Configurations'>
                        <ListItemIcon>
                            <img className={classes.leftpanel} alt="logout Logo" src={DevicesIcon} /></ListItemIcon>
                        <ListItemText primary={"Configurations"} />
                    </ListItem>

                    <Collapse in={openDevices} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem style={{
                                marginLeft: '0.5em',
                                background: (active === device_config_page || active === conn_config_page ? '#1D1D25' : '#2B2B36'),
                            }}
                                onClick={() => {
                                    active === 'device-config' ? window.location.reload() : history.push("/device-config");
                                    setActive(device_config_page);
                                    handleDrawerClose();
                                }} button className={classes.nested}>
                                <ArrowRightIcon style={{ color: "#FCFCFC", marginRight: '0.5em' }} />
                                <ListItemText primary="Device Configurations"
                                    style={{ color: (active === device_config_page ? '#0089FF' : '#FCFCFC') }} />
                            </ListItem>
                            <ListItem style={{
                                marginLeft: '0.5em',
                                background: (active === device_config_page || active === conn_config_page ? '#1D1D25' : '#2B2B36')
                            }} onClick={() => {
                                active === 'conn-config' ? window.location.reload() : history.push("/conn-config");
                                setActive(conn_config_page);
                                handleDrawerClose();
                            }} button className={classes.nested}>
                                <ArrowRightIcon style={{ color: "#FCFCFC", marginRight: '0.5em' }} />
                                <ListItemText primary="Connection Configurations"
                                    style={{ color: (active === conn_config_page ? '#0089FF' : '#FCFCFC') }} />
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem onClick={() => {
                        active === 'resources' ? window.location.reload() : history.push("/resources");
                        setActive('resources');
                        handleDrawerClose();
                    }} style={{
                        marginTop: '0.1em',
                        marginBottom: '0.5em',
                        marginLeft: '0.5em',
                        background: (active === 'resources' ? '#1D1D25' : '#2B2B36'),
                        borderBottom: (active === 'resources' ? border_bottom_style : '')
                    }} button key='Resources'>
                        <ListItemIcon><img className={classes.leftpanel}
                            style={{ width: "1.3em", height: "1.3em", marginLeft: '-0.3em' }}
                            alt="logout Logo" src={ResourcesIcon} /></ListItemIcon>
                        <ListItemText primary={"Resources"} />
                    </ListItem>
                    <ListItem onClick={() => {
                        history.push("/events");
                        setActive('events');
                        handleDrawerClose();
                    }} style={{
                        marginTop: '0.1em',
                        marginBottom: '0.5em',
                        marginLeft: '0.5em',
                        background: (active === 'events' ? '#1D1D25' : '#2B2B36'),
                        borderBottom: (active === 'events' ? border_bottom_style : '')
                    }} button key='Events'>
                        <ListItemIcon><img className={classes.leftpanel} alt="logout Logo"
                            src={EventIcon} /></ListItemIcon>
                        <ListItemText primary={"Events"} />
                    </ListItem>
                    <ListItem onClick={() => {
                        handleClickUsers();
                    }} style={{
                        marginTop: '0.1em',
                        marginLeft: '0.5em',
                        background: ((active === 'users' || active === 'roles' || active === 'applications') ? '#1D1D25' : '#2B2B36'),
                        borderBottom: ((active === 'users' || active === 'roles' || active === 'applications') ? border_bottom_style : '')
                    }} button key='Portal'>
                        <ListItemIcon><img className={classes.leftpanel}
                            style={{ width: "1.4em", height: "1.4em", marginLeft: '-0.3em' }}
                            alt="logout Logo" src={PortalIcon} /></ListItemIcon>
                        <ListItemText primary={"Portal"} />
                    </ListItem>
                    <Collapse in={openUsers} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                            <ListItem onClick={() => {
                                active === 'applications' ? window.location.reload() : history.push("/applications");
                                setActive('applications');
                                handleDrawerClose();
                            }} style={{
                                marginLeft: '0.5em',
                                background: ((active === 'users' || active === 'roles' || active === 'applications') ? '#1D1D25' : '#2B2B36')
                            }} button className={classes.nested}>
                                <ArrowRightIcon style={{ color: "#FCFCFC", marginRight: '0.5em' }} />
                                <ListItemText primary="Applications"
                                    style={{ color: (active === 'applications' ? '#0089FF' : '#FCFCFC') }} />
                            </ListItem>
                            <ListItem onClick={() => {
                                active === 'roles' ? window.location.reload() : history.push("/roles");
                                setActive('roles');
                                handleDrawerClose();
                            }} style={{
                                marginLeft: '0.5em',
                                background: ((active === 'users' || active === 'roles' || active === 'applications') ? '#1D1D25' : '#2B2B36')
                            }} button className={classes.nested}>
                                <ArrowRightIcon style={{ color: "#FCFCFC", marginRight: '0.5em' }} />
                                <ListItemText primary="Roles"
                                    style={{ color: (active === 'roles' ? '#0089FF' : '#FCFCFC') }} />
                            </ListItem>
                            <ListItem style={{
                                marginLeft: '0.5em',
                                background: ((active === 'users' || active === 'roles' || active === 'applications') ? '#1D1D25' : '#2B2B36')
                            }} onClick={() => {
                                active === 'users' ? window.location.reload() :history.push("/users");
                                setActive('users');
                                handleDrawerClose();
                            }} button className={classes.nested}>
                                <ArrowRightIcon style={{ color: "#FCFCFC", marginRight: '0.5em' }} />
                                <ListItemText primary="Users"
                                    style={{ color: (active === 'users' ? '#0089FF' : '#FCFCFC') }} />
                            </ListItem>

                        </List>
                    </Collapse>
                </List>
            </Drawer>
            <div className={classes.toolbarMargin}></div>
        </React.Fragment>
    );
};
