import { useState, useEffect, useCallback } from "react";
import axios, { ResponseType } from "axios";
import attemptTokenRefresh from "./attemptTokenRefresh";
import useInterval from "../useInterval";

const BASE_URL = `${window.SERVER_DATA.REACT_APP_PM_API_HOST}/api/v1d`;

const DEFAULT_MOCK_TIMEOUT = 2000;
const TOKEN_STORAGE_KEY = "sso_token";

const getApiKey = () => window.SERVER_DATA.REACT_APP_PM_API_KEY;

const getAccessToken = () => {
  const ssoToken = JSON.parse(
    sessionStorage.getItem(TOKEN_STORAGE_KEY) as string
  );
  return ssoToken.access_token;
};

const getPmSessionToken = () => {
  return sessionStorage.getItem("pm_s_token");
};

const getPmSessionId = () => {
  return sessionStorage.getItem("pm_s_id");
};

interface IMock {
  fetcher: (args?: any) => any;
  timeout?: number;
}

type RequestFormat = "json" | "form-data";
export type ReqMethod = "GET" | "POST" | "DELETE" | "PUT"

interface IConfig {
  method: ReqMethod;
  data?: Object;
  requestFormat?: RequestFormat;
  deferred?: boolean;
  mock?: IMock;
  pollingInterval?: number;
  responseDataFormatter?: (data: any) => any;
  onError?: (error: any) => void;
  doNotExtractError?: boolean;
  responseType?: ResponseType;
  includePMSCredentialsInRequestBody?: boolean;
  sendDataInGetRequestBody?: boolean;
}

interface IStatus {
  pending: boolean;
  error: string | null;
}

interface IReturnType {
  data: any;
  status: IStatus;
  trigger: (args?: any) => void;
}

const getRequestHeaders = (requestFormat: RequestFormat) => {
  const accessToken = getAccessToken();
  const apiKey = getApiKey();

  const basicHeaders = {
    Authorization: `Bearer ${accessToken}`,
    "Ocp-Apim-Subscription-Key": apiKey,
    "Ocp-Apim-Trace": `true`,
  };

  if (requestFormat === "json") {
    return basicHeaders;
  }

  if (requestFormat === "form-data") {
    return {
      "Content-Type": "multipart/form-data",
      ...basicHeaders,
    };
  }
};

const formatRequestData = (
  requestData: Object = {},
  requestFormat: RequestFormat = "json"
) => {
  if (requestFormat === "json") {
    return requestData;
  }

  const fd = new FormData();

  for (let key in requestData) {
    fd.append(key, requestData[key]);
  }

  fd.append("pm_s_id", String(getPmSessionId()));
  fd.append("pm_s_token", String(getPmSessionToken()));

  return fd;
};

const axiosPost = async (
  endPoint: string,
  requestData: Object | undefined,
  configs: IConfig
) => {
  const requestFormat = configs.requestFormat || "json";
  const { data: responseData } = await axios.post(
    BASE_URL + endPoint,
    formatRequestData(requestData, requestFormat),
    {
      headers: getRequestHeaders(requestFormat),
    }
  );
  return responseData;
};

const addAuthData = (data: any = {}) => {
  return {
    pm_s_token: getPmSessionToken(),
    pm_s_id: getPmSessionId(),
    ...data,
  };
};

const axiosDelete = async (endPoint: string, data: Object) => {
  const accessToken = getAccessToken();
  const apiKey = getApiKey();
  const updatedData = addAuthData(data);
  const { data: responseData } = await axios.delete(BASE_URL + endPoint, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Ocp-Apim-Subscription-Key": apiKey,
      "Ocp-Apim-Trace": `true`,
    },
    params: updatedData,
  });
  return responseData;
};

const axiosPut = async (endPoint: string, requestData: Object) => {
  const accessToken = getAccessToken();
  const apiKey = getApiKey();
  const updatedData = addAuthData(requestData);
  const { data: responseData } = await axios.put(
    BASE_URL + endPoint,
    updatedData,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Ocp-Apim-Subscription-Key": apiKey,
        "Ocp-Apim-Trace": `true`,
      },
    }
  );
  return responseData;
};

const axiosGet = async (endPoint: string, data: Object, configs: IConfig) => {
  const { responseType = "json", sendDataInGetRequestBody } = configs;
  const accessToken = getAccessToken();
  const apiKey = getApiKey();
  const updatedData = addAuthData(sendDataInGetRequestBody ? {} : data);

  const { data: responseData } = await axios.get(BASE_URL + endPoint, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Ocp-Apim-Subscription-Key": apiKey,
      "Ocp-Apim-Trace": `true`,
    },
    params: updatedData,
    ...(sendDataInGetRequestBody ? { data } : {}),
    responseType,
  });
  return responseData;
};

const readFromMock = async (mock: IMock, data: object) => {
  const { fetcher, timeout = DEFAULT_MOCK_TIMEOUT } = mock;
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const result = await fetcher(data);
        resolve(result);
      } catch (ex) {
        reject(ex);
      }
    }, timeout);
  });
};

const readFromApi = async (
  endPoint: string,
  configs: IConfig,
  requestData: any
) => {
  if (configs.method === "DELETE") {
    return axiosDelete(endPoint, requestData);
  }
  if (configs.method === "PUT") {
    return axiosPut(endPoint, requestData);
  }

  const data = await (configs.method === "POST"
    ? axiosPost(endPoint, requestData, configs)
    : axiosGet(endPoint, requestData, configs));

  return data;
};

const mergeRequestData = (
  defaultData: Object = {},
  requestPayload: Object = {}
) => ({
  ...defaultData,
  ...requestPayload,
});

const isTokenExpired = (err: any) => {
  return (err?.response?.data?.message || "").toLowerCase() === "token expired";
};

const isAuthenticationError = (err: any) => {
  return (
    (err?.response?.data?.message || "").toLowerCase() ===
    "authentication error"
  );
};

const extractError = (err: any) => {
  return err?.response?.data?.message || err?.message || "Unknown error";
};

const logoutUser = () => {
  sessionStorage.removeItem(TOKEN_STORAGE_KEY);
  window.location.href = "/";
};

const useApi = (endPoint: string, configurations: IConfig): IReturnType => {
  /*Configs should stay unchanged. In future, if it needs to be updated dynamically,
  useApi can return a setter to consumer component.*/
  const [configs] = useState(configurations); //NO. This is not PROPS. :D
  const [data, setData] = useState(null);
  const [status, setStatus] = useState<IStatus>({
    pending: false,
    error: null,
  });

  const fetchData = useCallback(
    async (requestPayload: any = {}, isReAttempt = false) => {
      setStatus({ pending: true, error: null });
      let requestData: any = mergeRequestData(configs.data, requestPayload);

      if (
        configs.method === "POST" &&
        configs.includePMSCredentialsInRequestBody
      ) {
        requestData = {
          ...requestData,
          pm_s_id: getPmSessionId(),
          pm_s_token: getPmSessionToken(),
        };
      }

      try {
        let data: any = await (configs.mock
          ? readFromMock(configs.mock, requestData)
          : readFromApi(endPoint, configs, requestData));

        if (configs.responseDataFormatter) {
          data = configs.responseDataFormatter(data);
        }
        setData(data);
        setStatus({ pending: false, error: null });
      } catch (ex) {
        console.warn("Exception captured", ex);
        if (ex instanceof Error) {
          if (isTokenExpired(ex) && !isReAttempt) {
            const canRetry = await attemptTokenRefresh();
            if (canRetry) {
              fetchData(requestPayload, true); //let's call it with requestPayload. requestData will not do any harm though
            } else {
              console.warn("token refresh attempt failed");
              logoutUser();
            }
          } else if (isAuthenticationError(ex)) {
            console.warn("Authentication error");
            logoutUser();
          } else {
            /*
            Setting `doNotExtract` error flag will return the error object as is
            to the onError function.
            */
            const extractedError = extractError(ex);
            setStatus({ pending: false, error: extractedError });
            configs.onError &&
              configs.onError(configs.doNotExtractError ? ex : extractedError);
          }
        }
      }
    },
    [endPoint, configs]
  );

  useInterval(
    () => configs.pollingInterval && fetchData(configs.data),
    configs.pollingInterval
  );

  useEffect(() => {
    if (!configs.deferred) {
      fetchData();
    }
  }, [endPoint, configs.deferred, fetchData]);

  return { data, status, trigger: fetchData };
};

//use this one if you need to read anything from a method
//remove unwanted configurations items
const callApi = async (
  endPoint: string,
  configs: IConfig,
  isReAttempt = false
) => {
  let requestData: any = configs.data;

  if (configs.method === "POST" && configs.includePMSCredentialsInRequestBody) {
    requestData = {
      ...requestData,
      pm_s_id: getPmSessionId(),
      pm_s_token: getPmSessionToken(),
    };
  }

  try {
    let data: any = await (configs.mock
      ? readFromMock(configs.mock, requestData)
      : readFromApi(endPoint, configs, requestData));

    if (configs.responseDataFormatter) {
      data = configs.responseDataFormatter(data);
    }
    return data;
  } catch (ex) {
    console.warn("Exception captured", ex);
    if (ex instanceof Error) {
      if (isTokenExpired(ex) && !isReAttempt) {
        const canRetry = await attemptTokenRefresh();
        if (canRetry) {
          callApi(endPoint, configs, true); //let's call it with requestPayload. requestData will not do any harm though
        } else {
          console.warn("token refresh attempt failed");
          logoutUser();
        }
      } else if (isAuthenticationError(ex)) {
        console.warn("Authentication error");
        logoutUser();
      } else {
        throw ex;
      }
    }
  }
};

export default useApi;

export { getPmSessionId, getPmSessionToken, callApi };
