import React from "react";
import { FormSection, CustomCheckbox } from "../../../UiComponents";
import CustomTextField from "../../../UiComponents/TextField/TextField";
import { Grid, IconButton } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import useStyles from "../EditConfiguration.styles";
import { TAG_DATA_TYPE } from "../../../../utils/constant";

const getInstructionByTagType = (tagType: string) => {
  return (
    {
      dropdown: "(Enter options separated by | )",
      tabular: "(Enter column names separated by | )",
    }[tagType.toLowerCase()] || ""
  );
};

const TagTypeIndicator: React.FC<any> = ({ tagData }) => {
  const { type, data_type, _isNew } = tagData;
  return (
    <div style={{ fontSize: "12px", height: "1.35em" }}>
      Type:
      {` ${type} ${data_type === TAG_DATA_TYPE.number ? "(numbers only)" : ""}`}
      {_isNew && (
        <span
          style={{
            color: "rgb(97, 226, 97)",
            border: "1px solid green",
            background: "#464545",
            padding: "1px 2px",
            marginLeft: "5px",
          }}
        >
          new
        </span>
      )}
    </div>
  );
};

const TagRenderer: React.FC<any> = ({
  name,
  tagData,
  onTagChange,
  onTagDelete,
}) => {
  const { type, data_type, is_visible_as_column, updatedVisibility, _isNew } =
    tagData;

  const classes = useStyles();
  const isTabularTag = type === "Tabular";
  const isDropdown = type === "Dropdown";
  return (
    <Grid item xs={6}>
      <div className={classes.tagWrapper}>
        <div className={classes.tagHeaderWrapper}>
          <div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "2px",
              }}
            >
              {`${name} ${getInstructionByTagType(type)}`}
            </div>
            <TagTypeIndicator tagData={tagData} />
          </div>
          <div style={{ display: "flex" }}>
            {!isTabularTag && (
              <CustomCheckbox
                name={name}
                checked={
                  updatedVisibility === undefined
                    ? is_visible_as_column
                    : updatedVisibility
                }
                readOnly={_isNew ? false : is_visible_as_column}
                onChange={(checkedState: boolean) =>
                  onTagChange(name, {
                    ...tagData,
                    updatedVisibility: checkedState,
                  })
                }
                label="Column View"
              />
            )}
            {/* NOTE: Disabled style defined in EditConfiguration.styles.tsx */}
            <IconButton disabled={!_isNew} onClick={() => onTagDelete(name)}>
              <DeleteIcon color="secondary" />
            </IconButton>
          </div>
        </div>

        <CustomTextField
          style={{
            marginTop: "0",
            paddingTop: "0",
          }}
          value={isDropdown ? tagData.options : tagData.value}
          readOnly={isTabularTag && !_isNew}
          onChange={(value: string) => {
            //TODO: Make onChange and onBlur handler same
            if (isDropdown) {
              onTagChange(name, { ...tagData, options: value });
            } else {
              onTagChange(name, { ...tagData, value });
            }
          }}
          onBlur={(value: string) => {
            if (isDropdown) {
              onTagChange(name, { ...tagData, options: value.trim() });
            } else {
              onTagChange(name, { ...tagData, value: value.trim() });
            }
          }}
        />
      </div>
    </Grid>
  );
};

const Tags: React.FC<any> = ({ tags, onTagChange, onTagDelete }) => {
  return (
    <FormSection header="Tags">
      {Object.keys(tags).map((tag: any) => (
        <TagRenderer
          name={tag}
          tagData={tags[tag]}
          onTagChange={onTagChange}
          onTagDelete={onTagDelete}
          key={tag}
        />
      ))}
    </FormSection>
  );
};

export default Tags;
